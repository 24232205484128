import React, { useEffect, useRef,useState } from "react";
import { FormattedMessage ,useIntl  } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/menu-img/llms.png";
import Image from "next/image";


import useList from '@/components/isAuth/isAuth';


const BottomFeatures = () => {
    const list = useList();
    const intl = useIntl();

    const userID = list[0];
 
    return (
        <>
       <style jsx>{`
  .smart-ai-section {
    padding: 80px 0;
    background: #fff;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .smart-header {
    max-width: 600px;
  }
  .smart-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .smart-title .way {
    color: #4776E6;
  }
  .smart-title .use-ai {
    color: #E94E8C;
  }
  .smart-description {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  .feature-box {
    background: linear-gradient(145deg, #f8f9ff 0%, #fff1f8 100%);
    border-radius: 20px;
    padding: 40px;
    max-width: 400px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  }
  .feature-text {
    font-size: 24px;
    color: #333;
    line-height: 1.4;
    margin-bottom: 15px;
  }
`}</style>
<section className="smart-ai-section">
  <div className="container">
    <div className="smart-header">
      <h1 className="smart-title">
        <FormattedMessage
          id="smart.way.title"
          defaultMessage="The {smart} {useAI}"
          values={{
            smart: <span className="way">Smart Way</span>,
            useAI: <span className="use-ai">to Use AI</span>
          }}
        />
      </h1>
      <p className="smart-description">
        <FormattedMessage
          id="smart.way.description1"
          defaultMessage="Future-proof your AI workflow with automatic access to the latest models and capabilities. Scale effortlessly as your needs grow with our flexible platform."
        />
      </p>
      <p className="smart-description">
        <FormattedMessage
          id="smart.way.description2"
          defaultMessage="Leverage our intelligent task routing and specialized assistants to maximize productivity and innovation across your organization."
        />
      </p>
    </div>
    <div className="feature-box">
      <p className="feature-text">
        <FormattedMessage
          id="smart.way.saving"
          defaultMessage="Save up to 4X compared to individual subscriptions. Access all major AI models with a single subscription."
        />
      </p>
    </div>
  </div>
</section>

        </>
      );
      
    
    
    
};

export default BottomFeatures;
