"use client";

import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image';

// Image imports
import liquidWorld from "../../public/images/llm-home/liquid-world.jpeg";
import trading from "../../public/images/llm-home/trading.jpeg";
import classroom from "../../public/images/llm-home/classroom.jpeg";
import drone from "../../public/images/llm-home/drone.jpeg";
import quantumFashion from "../../public/images/llm-home/quantum-fashion.jpeg";
import lion1 from "../../public/images/llm-home/lion1.jpeg";
import flower from "../../public/images/llm-home/flower.jpeg";
import forestSpirit from "../../public/images/llm-home/forest-spirit.jpeg";
import stars from "../../public/images/llm-home/stars.jpeg";
import electricCar from "../../public/images/llm-home/electric-car.jpeg";
import burger from "../../public/images/llm-home/burger.jpeg";
import cosmetic from "../../public/images/llm-home/cosmetic.jpeg";
import dental from "../../public/images/llm-home/dental.jpeg";
import portrait from "../../public/images/llm-home/portrait.jpeg";
import watch1 from "../../public/images/llm-home/watch1.jpeg";
import architecture from "../../public/images/llm-home/architecture.jpeg";
import fashion1 from "../../public/images/llm-home/fashion1.jpeg";
import interior from "../../public/images/llm-home/interior.jpeg";
import style1 from "../../public/images/llm-home/style1.jpeg";
import style2 from "../../public/images/llm-home/style2.jpeg";
import style3 from "../../public/images/llm-home/style3.jpeg";
import { FormattedMessage, useIntl } from 'react-intl';

// ... other imports

const ImageGeneratorShowcase = () => {
  const [activeTab, setActiveTab] = useState('capabilities');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intl = useIntl();

  const examples = [
    {
      image: liquidWorld,
      prompt: "Hyper-realistic paint photography, vibrant liquid colors smoothly morphing into a surreal cityscape, frozen moment of transformation, macro lens detail, abstract art meets architecture, 8k resolution", 
    },
    {
      image: trading,
      prompt: "Modern trading floor with multiple display screens showing market data, fintech photography, dramatic corporate lighting",
    },
    {
      image: classroom,
      prompt: "High-tech classroom with holographic learning displays, diverse student engagement, educational technology photography, bright and inspiring atmosphere",
    },
    {
      image: drone,
      prompt: "Drone monitoring vast agricultural fields, precision farming technology, aerial agricultural photography, golden hour lighting",
    },
    {
      image: quantumFashion,
      prompt: "High-fashion photography meets quantum mechanics, model wearing a dress made of probability waves and quantum entanglement, shot in an infinity mirror room, magazine editorial style",
    },
    {
      image: electricCar,
      prompt: "Electric vehicle in minimalist white studio, dramatic lighting highlighting design features, automotive photography, commercial quality, detailed reflections",
    },
    {
      image: burger,
      prompt: "Gourmet burger with melting cheese and fresh ingredients suspended in mid-air, commercial food photography, studio lighting, splash photography, advertising quality",
    },
    {
      image: cosmetic,
      prompt: "High-end cosmetic product photography, glass bottle with liquid foundation, water drops on surface, soft pastel background, professional beauty advertising style, ultra-sharp details",
    },
    {
      image: dental,
      prompt: "Dental clinic interior with state-of-the-art equipment, warm welcoming atmosphere, professional medical facility photography, commercial healthcare style",
    },
    {
      image: portrait,
      prompt: "Professional headshot of a business executive in a modern office setting",
    }
  ];
  const features = [
    {
      icon: "lightbulb",
      title: "Dual AI Models",
      description: "DALL-E 3 + Imagen 3 Combined for superior quality and accuracy"
    },
    {
      icon: "message-square",
      title: "Natural Language Input",
      description: "Describe your vision in plain words and watch it come to life"
    },
    {
      icon: "maximize",
      title: "High-Resolution Output",
      description: "Generate crystal clear images up to 1024x1024 pixels"
    },
    {
      icon: "palette",
      title: "Multiple Styles",
      description: "Choose from photorealistic, artistic, abstract, and more"
    },
    {
      icon: "zap",
      title: "Lightning Fast",
      description: "Get your results in seconds with our optimized pipeline"
    },
    {
      icon: "sliders",
      title: "Fine Control",
      description: "Adjust parameters to get exactly the results you want"
    }
  ];

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % examples.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + examples.length) % examples.length);
  };

  const sectionRefs = {
    capabilities: useRef(null),
    gallery: useRef(null),
    versatility: useRef(null),
    photorealistic: useRef(null)
  };

  const scrollToSection = (sectionId) => {
    setActiveTab(sectionId);
    sectionRefs[sectionId].current?.scrollIntoView({ behavior: 'smooth' });
  };


  useEffect(() => {
    const handleScroll = () => {
      
     
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % examples.length);
  };
  
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? examples.length - 1 : prev - 1));
  };
  
  useEffect(() => {
    const track = document.querySelector('.gallery-slider-track');
    if (track) {
      track.style.transform = `translateX(-${currentSlide * 100}%)`;
    }
  }, [currentSlide]);
  
  return (
    <div className="container">
      {/* Title Section */}
      <div className="title-section">
      <h1 className="main-title">
        <FormattedMessage
          id="app.title"
          defaultMessage="Create Professional Visuals with AI"
          description="Ana başlık"
        />
      </h1>
      <p className="description">
        <FormattedMessage
          id="app.description"
          defaultMessage="Experience the power of cutting-edge AI with our Image Generator. Harness the capabilities of DALL-E 3 and Imagen 3 models to turn your imaginative prompts into stunning visual creations."
          description="Açıklama metni"
        />
      </p>

      {/* Navigation Tabs */}
      <div className="tab-container">
        {[
          {
            id: 'capabilities',
            label: intl.formatMessage({
              id: 'tab.capabilities',
              defaultMessage: 'Capabilities'
            })
          },
          {
            id: 'gallery',
            label: intl.formatMessage({
              id: 'tab.gallery',
              defaultMessage: 'Gallery'
            })
          },
          {
            id: 'versatility',
            label: intl.formatMessage({
              id: 'tab.versatility',
              defaultMessage: 'Versatility & understanding'
            })
          },
          {
            id: 'photorealistic',
            label: intl.formatMessage({
              id: 'tab.photorealistic',
              defaultMessage: 'Photorealistic Images'
            })
          }
        ].map((tab) => (
          <button
            key={tab.id}
            onClick={() => scrollToSection(tab.id)}
            className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  
      {/* Capabilities Section */}
      <section ref={sectionRefs.capabilities} className="content-section">
  <div className="content-grid">
    <div className="features-panel">
      <h2 className="section-title">
        <FormattedMessage
          id="section.title"
          defaultMessage="AI-Powered Creation"
        />
      </h2>
      <div className="features-list">
        {[
          {
            icon: "🎨",
            titleId: "feature.dualAI.title",
            descriptionId: "feature.dualAI.description",
            defaultTitle: "Dual AI Models",
            defaultDescription: "DALL-E 3 + Imagen 3 Combined for superior quality"
          },
          {
            icon: "💭",
            titleId: "feature.naturalLanguage.title",
            descriptionId: "feature.naturalLanguage.description",
            defaultTitle: "Natural Language Input",
            defaultDescription: "Describe your vision in plain words"
          },
          {
            icon: "✨",
            titleId: "feature.highRes.title",
            descriptionId: "feature.highRes.description",
            defaultTitle: "High-Resolution Output",
            defaultDescription: "Generate crystal clear images up to 1024x1024 pixels"
          },
          {
            icon: "🎯",
            titleId: "feature.styles.title",
            descriptionId: "feature.styles.description",
            defaultTitle: "Multiple Styles",
            defaultDescription: "Choose from photorealistic, artistic, abstract, and more"
          },
          {
            icon: "⚡",
            titleId: "feature.fast.title",
            descriptionId: "feature.fast.description",
            defaultTitle: "Lightning Fast",
            defaultDescription: "Get your results in seconds"
          },
          {
            icon: "🎚️",
            titleId: "feature.control.title",
            descriptionId: "feature.control.description",
            defaultTitle: "Fine Control",
            defaultDescription: "Adjust parameters for perfect results"
          }
        ].map((feature, index) => (
          <div key={index} className="feature-item">
            <span className="feature-icon">{feature.icon}</span>
            <div className="feature-content">
              <h3>
                <FormattedMessage
                  id={feature.titleId}
                  defaultMessage={feature.defaultTitle}
                />
              </h3>
              <p>
                <FormattedMessage
                  id={feature.descriptionId}
                  defaultMessage={feature.defaultDescription}
                />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>

    <div className="examples-grid">
      <div className="image-wrapper">
        <Image 
          src={lion1} 
          alt={intl.formatMessage({ 
            id: "image.lion.alt",
            defaultMessage: "AI Generated Lion"
          })} 
          fill 
          className="showcase-image" 
        />
      </div>
      <div className="image-wrapper">
        <Image 
          src={flower} 
          alt={intl.formatMessage({ 
            id: "image.flower.alt",
            defaultMessage: "AI Generated Flower"
          })} 
          fill 
          className="showcase-image" 
        />
      </div>
      <div className="image-wrapper">
        <Image 
          src={forestSpirit} 
          alt={intl.formatMessage({ 
            id: "image.forestSpirit.alt",
            defaultMessage: "AI Generated Forest Spirit"
          })} 
          fill 
          className="showcase-image" 
        />
      </div>
      <div className="image-wrapper">
        <Image 
          src={stars} 
          alt={intl.formatMessage({ 
            id: "image.stars.alt",
            defaultMessage: "AI Generated Stars"
          })} 
          fill 
          className="showcase-image" 
        />
      </div>
    </div>
  </div>
</section>
  
      {/* Gallery Section */}
      <section ref={sectionRefs.gallery} className="gallery-modern-gallery">
  <div className="gallery-slider-container">
    <div className="gallery-slider-track">
      {examples.map((example, index) => (
        <div key={index} className="gallery-slide">
          <div className="gallery-image-wrapper">
            <Image
              src={example.image}
              alt={intl.formatMessage(
                { id: `gallery.slide.${index + 1}.alt`,
                  defaultMessage: `Slide ${index + 1}`
                }
              )}
              fill
              objectFit="cover"
              priority
            />
          </div>
          <div className="gallery-prompt-overlay">
            <p className="gallery-prompt-text">
              <FormattedMessage
                id={`gallery.prompt.${index + 1}`}
                defaultMessage={example.prompt}
              />
            </p>
          </div>
        </div>
      ))}
    </div>
    <button 
      className="gallery-nav-button gallery-prev" 
      onClick={prevSlide}
      aria-label={intl.formatMessage({
        id: "gallery.button.previous",
        defaultMessage: "Previous slide"
      })}
    >
      <span>‹</span>
    </button>
    <button 
      className="gallery-nav-button gallery-next" 
      onClick={nextSlide}
      aria-label={intl.formatMessage({
        id: "gallery.button.next",
        defaultMessage: "Next slide"
      })}
    >
      <span>›</span>
    </button>
  </div>
</section>

      {/* Versality Section*/}

<section ref={sectionRefs.versatility} className="content-section">
  <div className="versatility-container">
    {/* SOL BÖLÜM */}
    <div className="versatility-left">
      <div className="versatility-header">
        <i className="feather-zap"></i>
        <h2>
          <FormattedMessage id="versatility.header" defaultMessage="Style & Precision" />
        </h2>
      </div>
      
      <div className="versatility-feature full-width">
        <div className="content">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-eye"></i>
            <h3 style={{marginLeft:5}}>
              <FormattedMessage id="versatility.smart.title" defaultMessage="Smart Understanding" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.smart.description" 
              defaultMessage="Advanced AI that comprehends complex visual concepts, relationships, and artistic nuances" 
            />
          </p>
        </div>
      </div>
      
      <div className="versatility-features-grid">
        {/* Feature 1 */}
        <div className="versatility-feature">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-edit-2"></i>
            <h3 style={{marginLeft:10}}>
              <FormattedMessage id="versatility.feature.artistic.title" defaultMessage="Artistic Styles" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.feature.artistic.description" 
              defaultMessage="Mastery over diverse artistic styles and techniques" 
            />
          </p>
        </div>

        {/* Feature 2 */}
        <div className="versatility-feature">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-sun"></i>
            <h3 style={{marginLeft:10}}>
              <FormattedMessage id="versatility.feature.lighting.title" defaultMessage="Lighting Control" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.feature.lighting.description" 
              defaultMessage="Perfect manipulation of light, shadows, and atmosphere" 
            />
          </p>
        </div>

        {/* Feature 3 */}
        <div className="versatility-feature">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-camera"></i>
            <h3 style={{marginLeft:10}}>
              <FormattedMessage id="versatility.feature.camera.title" defaultMessage="Pro Camera" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.feature.camera.description" 
              defaultMessage="Advanced photographic techniques and compositions" 
            />
          </p>
        </div>

        {/* Feature 4 */}
        <div className="versatility-feature">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-layers"></i>
            <h3 style={{marginLeft:10}}>
              <FormattedMessage id="versatility.feature.details.title" defaultMessage="Rich Details" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.feature.details.description" 
              defaultMessage="Intricate textures and material properties" 
            />
          </p>
        </div>

        {/* Feature 5 */}
        <div className="versatility-feature">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-message-square"></i>
            <h3 style={{marginLeft:10}}>
              <FormattedMessage id="versatility.feature.prompts.title" defaultMessage="Smart Prompts" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.feature.prompts.description" 
              defaultMessage="Natural language to stunning visuals" 
            />
          </p>
        </div>

        {/* Feature 6 */}
        <div className="versatility-feature">
          <div style={{flexDirection:'row', display:'flex', alignItems:'center'}}>
            <i className="feather-star"></i>
            <h3 style={{marginLeft:10}}>
              <FormattedMessage id="versatility.feature.freedom.title" defaultMessage="Creative Freedom" />
            </h3>
          </div>
          <p>
            <FormattedMessage 
              id="versatility.feature.freedom.description" 
              defaultMessage="Unlimited possibilities for artistic expression and innovation" 
            />
          </p>
        </div>
      </div>
    </div>

    {/* SAĞ BÖLÜM */}
    <div className="versatility-right">
      <h2>
        <FormattedMessage id="versatility.transform.title" defaultMessage="Transformative Power" />
      </h2>
      <p>
        <FormattedMessage 
          id="versatility.transform.description" 
          defaultMessage="Watch your ideas come to life with precise control" 
        />
      </p>
      
      <div className="versatility-style-transform">
        <h3>
          <FormattedMessage id="versatility.style.title" defaultMessage="Style Transformation" />
        </h3>
        <div className="versatility-images">
          <div className='versatility-image-container'> 
            <Image
              src={style1}
              alt={intl.formatMessage({ id: "image.alt.castle", defaultMessage: "Fantasy castle" })}
              objectFit="cover"
              fill
            />
          </div>
          <div className='versatility-image-container'> 
            <Image
              src={style2}
              alt={intl.formatMessage({ id: "image.alt.landscape", defaultMessage: "Landscape transformation" })}
              objectFit="cover"
              fill
            />
          </div>
          <div className='versatility-image-container'> 
            <Image
              src={style3}
              alt={intl.formatMessage({ id: "image.alt.water", defaultMessage: "Water drop" })}
              objectFit="cover"
              fill
            />
          </div>
        </div>
      </div>

      <div className="versatility-controls">
        <h3>
          <FormattedMessage id="versatility.control.title" defaultMessage="Fine-Tuned Control" />
        </h3>
        <div className="versatility-slider">
          <div className="versatility-slider-item">
            <span>
              <FormattedMessage id="versatility.control.lighting" defaultMessage="Lighting" />
            </span>
            <div className="versatility-progress-bar"></div>
          </div>
          <div className="versatility-slider-item">
            <span>
              <FormattedMessage id="versatility.control.detail" defaultMessage="Detail Level" />
            </span>
            <div className="versatility-progress-bar1"></div>
          </div>
          <div className="versatility-slider-item">
            <span>
              <FormattedMessage id="versatility.control.style" defaultMessage="Style Strength" />
            </span>
            <div className="versatility-progress-bar2"></div>
          </div>
        </div>
      </div>

      <div className="versatility-buttons">
        <button className="versatility-btn">
          <div className='vers-title'>
            <FormattedMessage 
              id="versatility.button.styles.title" 
              defaultMessage="Try Different Styles" 
            />
          </div>
          <div className='vers-desc'>
            <FormattedMessage 
              id="versatility.button.styles.description" 
              defaultMessage="Explore various artistic styles with one click" 
            />
          </div>
        </button>
        <button className="versatility-btn">
          <div className='vers-title'>
            <FormattedMessage 
              id="versatility.button.settings.title" 
              defaultMessage="Adjust Settings" 
            />
          </div>
          <div className='vers-desc'>
            <FormattedMessage 
              id="versatility.button.settings.description" 
              defaultMessage="Fine-tune your creation to perfection" 
            />
          </div>
        </button>
      </div>
    </div>
  </div>
</section>

  

<section ref={sectionRefs.photorealistic} className="photorealistic-section">
  <div className="photorealistic-grid">
    {/* Left Side - Feature Cards */}
    <div className="feature-cards">
      <div className="feature-container">
        <div className="header">
          <div className="icon-wrapper">
            <i className="feather-camera"></i>
          </div>
          <h3 className="title">
            <FormattedMessage id="photo.title" defaultMessage="Photorealistic Magic" />
          </h3>
        </div>
        
        <div className="cards-list">
          <div className="card">
            <h4>
              <FormattedMessage id="photo.quality.title" defaultMessage="Professional Quality" />
            </h4>
            <div className="bullet-points">
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.quality.point1" defaultMessage="Studio-grade lighting control" />
                </p>
              </div>
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.quality.point2" defaultMessage="True-to-life color accuracy" />
                </p>
              </div>
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.quality.point3" defaultMessage="Depth and perspective mastery" />
                </p>
              </div>
            </div>
          </div>

          <div className="card">
            <h4>
              <FormattedMessage id="photo.material.title" defaultMessage="Material Excellence" />
            </h4>
            <div className="bullet-points">
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.material.point1" defaultMessage="Photorealistic textures" />
                </p>
              </div>
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.material.point2" defaultMessage="Surface detail precision" />
                </p>
              </div>
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.material.point3" defaultMessage="Real-world material physics" />
                </p>
              </div>
            </div>
          </div>

          <div className="card">
            <h4>
              <FormattedMessage id="photo.commercial.title" defaultMessage="Commercial Ready" />
            </h4>
            <div className="bullet-points">
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.commercial.point1" defaultMessage="Product visualization" />
                </p>
              </div>
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.commercial.point2" defaultMessage="Architectural renders" />
                </p>
              </div>
              <div className="bullet-item">
                <div className="bullet"></div>
                <p>
                  <FormattedMessage id="photo.commercial.point3" defaultMessage="Fashion and lifestyle" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Right Side - Interactive Demo */}
    <div className="demo-section">
      <div className="demo-container">
        <div className="demo-header">
          <h3>
            <FormattedMessage id="demo.title" defaultMessage="See the Difference" />
          </h3>
          <p>
            <FormattedMessage id="demo.description" defaultMessage="Compare AI-generated images with professional photography" />
          </p>
        </div>

        <div className="demo-grid">
          <div className="demo-column">
            <div className="image-wrapper1">
              <Image
                src={watch1}
                alt={intl.formatMessage({ id: "demo.image.product", defaultMessage: "AI Generated Product" })}
                layout="fill"
                objectFit="cover"
              />
              <div className="ai-badge">
                <FormattedMessage id="demo.badge" defaultMessage="AI Generated" />
              </div>
            </div>
            <div className="image-wrapper1">
              <Image
                src={architecture}
                alt={intl.formatMessage({ id: "demo.image.architecture", defaultMessage: "AI Generated Architecture" })}
                layout="fill"
                objectFit="cover"
              />
              <div className="ai-badge">
                <FormattedMessage id="demo.badge" defaultMessage="AI Generated" />
              </div>
            </div>
          </div>
          <div className="demo-column offset">
            <div className="image-wrapper1">
              <Image
                src={fashion1}
                alt={intl.formatMessage({ id: "demo.image.fashion", defaultMessage: "AI Generated Fashion" })}
                layout="fill"
                objectFit="cover"
              />
              <div className="ai-badge">
                <FormattedMessage id="demo.badge" defaultMessage="AI Generated" />
              </div>
            </div>
            <div className="image-wrapper1">
              <Image
                src={interior}
                alt={intl.formatMessage({ id: "demo.image.interior", defaultMessage: "AI Generated Interior" })}
                layout="fill"
                objectFit="cover"
              />
              <div className="ai-badge">
                <FormattedMessage id="demo.badge" defaultMessage="AI Generated" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  
      <style jsx>{`
        .container {
          max-width: 1280px;
          margin: 0 auto;
          padding: 48px 16px;
        }
        
        .content-section {
          margin-bottom: 96px;
        }
        .versatility-container {
    display: flex;
    gap: 2rem;
    padding: 2rem;
    max-width: 1400px;
    margin: 0 auto;
  }

.gallery-modern-gallery {
  background: #f5f5f5;
  display: flex;
  align-items: center;
}

.gallery-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.gallery-slider-track {
  display: flex;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.gallery-slide {
  position: relative;
  min-width: 100%;
  height: 100%;
}

.gallery-image-wrapper {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  aspect-ratio: 4/3;
  transition: transform 0.3s ease;
  background: #fff;
}

.gallery-prompt-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.85); /* Daha koyu ve transparan siyah */
}

.gallery-prompt-text {
  color: white;
  font-size: 14px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.gallery-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255,255,255,0.8);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
  transition: all 0.3s ease;
}

.gallery-nav-button:hover {
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.gallery-prev {
  left: 20px;
}

.gallery-next {
  right: 20px;
}
  .versatility-right {
    flex: 1;
    background: white;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }

  /* Increase left container size */
  .versatility-left {
    flex: 1;
    background: #f0f4ff;
    padding: 2rem;
    border-radius: 0.75rem;
  }
  .versatility-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;

  }
  .versatility-controls h3 {
    font-size: 1.9rem;
  }
  .versatility-header i {
    background: #4066ff;
    color: white;
    padding: 1rem;
    border-radius: 8px;
    font-size: 2.3rem;
  }

  .versatility-header h2 {
    font-size: 2.3rem;
  }
  .versatility-smart-understanding {
    margin-bottom: 1.5rem;
  }

  .versatility-smart-understanding h3 {
    font-size: 1.1rem;
    margin: 0.5rem 0;
  }

  .versatility-smart-understanding p {
    font-size: 0.9rem;
    color: #666;
  }

  .versatility-features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

 



  .versatility-right h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  .versatility-right > p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1.5rem;
  }

  .versatility-images {
    display: flex;
    gap: 0.75rem;
    margin: 1rem 0;
    position: relative;
  }


  .versatility-style-transform,
  .versatility-controls {
    background: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.06);
    margin-bottom: 2rem;
  }

  .versatility-images :global(span) {
    width: 180px !important;
    height: 120px !important;
  }

  .versatility-style-transform h3 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .versatility-image-description {
    display: flex;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }

  .versatility-image-description span {
    flex: 1;
    font-size: 0.8rem;
    color: #666;
    text-align: center;
  }

  .versatility-slider-item {
    margin: 0.75rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
}

.versatility-slider-item span {
    font-size: 1.2rem;
    color: #666;
    min-width: 120px; /* Yazı için minimum genişlik */
}


.versatility-progress-bar {
    height: 9px;
    background: #e0e0ff;
    border-radius: 3px;
    position: relative;
    flex: 1; /* Kalan alanı progress bar kaplasın */
}
.versatility-progress-bar::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 75%;
    background: #4066ff;
    border-radius: 3px;
}

.versatility-progress-bar1 {
    height: 9px;
    background: #e0e0ff;
    border-radius: 3px;
    position: relative;
    flex: 1; /* Kalan alanı progress bar kaplasın */
}
.versatility-progress-bar1::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 95%;
    background: #4066ff;
    border-radius: 3px;
}

.versatility-progress-bar2 {
    height: 9px;
    background: #e0e0ff;
    border-radius: 3px;
    position: relative;
    flex: 1; /* Kalan alanı progress bar kaplasın */
}
.versatility-progress-bar2::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 85%;
    background: #4066ff;
    border-radius: 3px;
}

.vers-desc{
   font-size: 1.2rem;
    color: #666;
    margin: 0;
    text-align: start;
}

.vers-title{
 font-weight:600;
    text-align: start;
}

  .versatility-images img {
    width: 150px !important;
    height: 100px !important;
    border-radius: 8px;
    object-fit: cover;
  }

  .versatility-images :global(span) {
    width: 150px !important;
    height: 100px !important;
  }
  .versatility-feature {
    background: white;
    padding: 1.7rem;
    border-radius: 1.2rem;
    position: relative;
  }

  .versatility-feature i {
    color: #4066ff;
    font-size: 1.5rem;
  }

  .versatility-feature h3 {
    font-size: 1.4rem;
    margin: 0.5rem 0;
  }

  .versatility-feature p {
    font-size: 1.2rem;
    color: #666;
    margin: 0;
  }

  .versatility-feature.full-width {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    background: white;
    padding: 1.2rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .versatility-feature.full-width .content {
    flex: 1;
  }

  .versatility-images {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }

  .versatility-image-container {
    position: relative;
    width: 175px;
    height: 175px;
    border-radius: 0.75rem;
    overflow: hidden;
  }

  .versatility-btn {
    position: relative;
    padding: 2rem 2.5rem;
    justify-content: center;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 1.5rem;
    background: #f0f4ff;
    border: none;
    cursor: pointer;
    font-size: 1.3rem;
    text-align: center;
    width: 200px;
  }

  .versatility-btn::after {
    content: attr(data-description);
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    width: 100%;
    font-size: 1.1rem;
    color: #666;
    text-align: center;
  }

  .versatility-buttons {
    display: flex;
    gap: 2rem;
    margin-top: 2.5rem;
  }


  .versatility-buttons > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-description {
    font-size: 0.8rem;
    color: #666;
    margin-top: 0.5rem;
    text-align: center;
  }



  .versatility-btn:hover {
    background: #e0e6ff;
  }
    .text-content {
      padding: 2rem;
      background: #f8fafc;
      border-radius: 1rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    }
    .photorealistic-section {
      margin-bottom: 96px;
    }

    .photorealistic-grid {
      display: grid;
      grid-template-columns: 5fr 7fr;
      gap: 32px;
    }



   
    .icon-wrapper {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      background-color: #3B82F6;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #1F2937;
    }

    .cards-list {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Kartlar arası boşluğu azalttım (24px'ten 16px'e) */
}

.card {
  background: white;
  padding: 20px; /* Padding'i azalttım (24px'ten 20px'e) */
  border:none;
  border-radius: 8px; /* Border radius'u azalttım */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Daha subtle bir gölge */
  transition: all 0.2s ease;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.card h4 {
  font-size: 1.6rem; /* Başlık boyutunu biraz küçülttüm */
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 8px; /* Başlık altı boşluğu azalttım */
}

.bullet-points {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Bullet noktaları arası boşluğu azalttım */
}

.bullet-item {
  display: flex;
  align-items: center;
  gap: 8px; /* Bullet ve metin arası boşluğu azalttım */
}

.bullet {
  min-width: 6px; /* Bullet boyutunu küçülttüm */
  height: 6px;
  border-radius: 50%;
  background-color: #3B82F6;
}

.bullet-item p {
  color: #4B5563;
  font-size: 1.4rem; /* Metin boyutunu biraz küçülttüm */
  margin: 0;
}

.feature-container {
  background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
  border-radius: 12px;
  padding: 24px; /* Container padding'ini azalttım */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px; /* Header içi boşluğu azalttım */
  margin-bottom: 24px; /* Header alt boşluğunu azalttım */
}
    .demo-container {
      background: linear-gradient(to bottom right, #f8f9fa, #ffffff);
      border-radius: 16px;
      padding: 32px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
      height: 100%;
    }

    .demo-header {
      margin-bottom: 32px;
    }

   .demo-header h3 {
    font-size: 2rem;
    font-weight: 800; 
    color: #1F2937;
    margin-bottom: 8px;
  }

    .demo-header p {
      color: #4B5563;
    }

    .demo-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }

    .demo-column {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .demo-column.offset {
      padding-top: 48px;
    }

    .image-wrapper1 {
  position: relative;
  aspect-ratio: 1;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.3s ease;

}
.image-wrapper {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.3s ease;

}

    .image-wrapper:hover {
      transform: scale(1.05);
    }

    .examples-grid .image-wrapper:nth-child(even) {
  transform: translateY(3rem); /* Moves even-numbered images down */
}

.examples-grid .image-wrapper:nth-child(3) {
  transform: translateY(-1rem); /* Slight upward adjustment for third image */
}

.examples-grid .image-wrapper:nth-child(4) {
  transform: translateY(2rem); /* Different offset for fourth image */
}

.image-wrapper:hover {
  transform: scale(1.05);
}

/* Preserve hover effect while maintaining offset */
.examples-grid .image-wrapper:nth-child(even):hover {
  transform: translateY(3rem) scale(1.05);
}

.examples-grid .image-wrapper:nth-child(3):hover {
  transform: translateY(-1rem) scale(1.05);
}

.examples-grid .image-wrapper:nth-child(4):hover {
  transform: translateY(2rem) scale(1.05);
}


    .ai-badge {
      position: absolute;
      top: 12px;
      left: 12px;
      background-color: #3B82F6;
      color: white;
      padding: 4px 12px;
      border-radius: 9999px;
      font-size: 0.875rem;
    }
    .text-content h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #1F2937;
    }

    .capability-list {
      list-style: none;
      padding: 0;
      margin-top: 1.5rem;
    }

    .capability-list li {
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #4B5563;
    }

    .image-showcase {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .image-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    .image-card {
      position: relative;
      aspect-ratio: 16/9;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .image-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.5rem;
      background: rgba(0, 0, 0, 0.7);
      color: white;
      font-size: 0.875rem;
      text-align: center;
    }

   
    
    .photorealistic-container {
      padding: 2rem;
      max-width: 1200px;
      margin: 0 auto;
    }

    .section-description {
      text-align: center;
      color: #4B5563;
      margin-bottom: 2rem;
      font-size: 1.125rem;
    }

    .photo-examples {
      margin-top: 2rem;
    }

    .photo-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5rem;
    }

    .photo-item {
      position: relative;
      aspect-ratio: 1;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .photo-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
      color: white;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .photo-item:hover .photo-overlay {
      opacity: 1;
    }

    .photo-overlay h4 {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }

    .photo-overlay p {
      font-size: 0.875rem;
      line-height: 1.4;
    }

    .photo-image {
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    .photo-item:hover .photo-image {
      transform: scale(1.05);
    }
        .content-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 2rem;
          padding: 2rem;
        }
        .title-section {
          text-align: center;
        }

        .main-title {
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 16px;
          color: #1F2937;
        }

        .description {
          font-size: 1.5rem;
          color: #4B5563;
          max-width: 768px;
          margin: 0 auto;
          margin-bottom: 32px;
        }

        .tab-container {
          display: flex;
          justify-content: center;
          gap: 16px;
          margin-bottom: 64px;
        }

        .tab-button {
          padding: 8px 24px;
          border-radius: 9999px;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          background-color: transparent;
          color: #4B5563;
        }

        .tab-button.active {
          background-color: #3B82F6;
          color: white;
        }

        .capabilities-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 32px;
          margin-bottom: 96px;
        }

        .features-container {
          padding: 32px;
          border-radius: 16px;
          background: linear-gradient(to bottom right, #EFF6FF, #DBEAFE);
        }

        .feature-header {
          margin-bottom: 32px;
        }

        .feature-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

   

        .image-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 6px;
        }

        .image-container {
          position: relative;
          aspect-ratio: 1;
          border-radius: 16px;
          overflow: hidden;
        }

        .showcase-image {
  object-fit: cover;
  transition: transform 0.3s ease;

}
        .features-panel {
  background: linear-gradient(to bottom right, #EFF6FF, #DBEAFE);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.feature-item {
  backdrop-filter: blur(4px);
  border-radius: 0.8rem;
  padding: 0.8rem; /* Padding'i azalttım */
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1.2rem; /* Icon ile içerik arasındaki boşluğu artırdım */
  min-height: 60px; /* Minimum yükseklik verdim */
}

.feature-icon {
  font-size: 2.4rem;
  color: #3B82F6;
  padding: 0.4rem; /* Icon'un içerisindeki boşluğu artırdım */
  width: 40px; /* Icon'un genişliğini sabitledim */
  height: 40px; /* Icon'un yüksekliğini sabitledim */
  justify-content: center;
  
    border-radius: 7px; /* Icon'a border-radius ekledim */
  background: rgba(255, 255, 255, 1);
  flex-shrink: 0; /* Icon'un küçülmesini engeller */
  display: flex;
  align-items: center;
}

.feature-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.2rem; /* Başlık ile açıklama arasındaki boşluğu azalttım */
}

.feature-content h3 {
  font-size: 1.5rem; /* Başlık boyutunu küçülttüm */
  font-weight: 600;
  margin: 0; /* Margin'i kaldırdım */
  line-height: 1.2;
  color: #1F2937;
}

.feature-content p {
  color: #1F2937;
  font-size: 1.2rem; /* Açıklama metnini küçülttüm */
  margin: 0; /* Margin'i kaldırdım */
  line-height: 1.4;
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem; /* Feature'lar arası boşluğu azalttım */
}

.feature-item:hover {
  background: rgba(255, 255, 255, 0.7);
  transform: translateY(-2px);
}

.feature-icon {
  font-size: 2rem;
  color: #3B82F6;
}
  
        .section-title {
          font-size: 1.875rem;
          font-weight: bold;
          margin-bottom: 2rem;
          color: #1F2937;
        }
  
        .features-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
  
      .feature-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
      }
  
  
        .feature-content h3 {
          font-size: 1.7rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
          color: #1F2937;
        }
  
        .feature-content p {
            color: #1F2937;
            font-size: 1.4rem;
        }
  
        .examples-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  position: relative;
}
  
        .showcase-image {
          object-fit: cover;
          transition: transform 0.3s ease;
        }
  
        .image-wrapper:hover .showcase-image {
          transform: scale(1.05);
        }
  
      
  
        .nav-button {
          padding: 0.5rem 1rem;
          background: #3B82F6;
          color: white;
          border: none;
          border-radius: 0.25rem;
          cursor: pointer;
          transition: background 0.3s ease;
        }
  
        .nav-button:hover {
          background: #2563EB;
        }
  
        .prompt-display {
          max-width: 768px;
          margin: 2rem auto;
          padding: 1rem;
          background: #f8fafc;
          border-radius: 0.5rem;
        }
      `}</style>
    </div>
  );
  
};

export default ImageGeneratorShowcase;

