import React,{useEffect,useState} from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/llm-home/oldcar.png";
import llms1 from "../../public/images/llm-home/research-21.png";
import llms2 from "../../public/images/llm-home/research-3.png";

import useList from '@/components/isAuth/isAuth';

const MediumBottom = () => {
    const list = useList();
    const intl = useIntl();
    const userID = list[0];

    // Slider içeriği
    const sliderContent = [
        {
            image: llms,
            title: intl.formatMessage({ id: "slider.slide1.title" }),
            description: intl.formatMessage({ id: "slider.slide1.description" })
        },
        {
            image: llms1,
            title: intl.formatMessage({ id: "slider.slide2.title" }),
            description: intl.formatMessage({ id: "slider.slide2.description" })
        },
        {
            image: llms2,
            title: intl.formatMessage({ id: "slider.slide3.title" }),
            description: intl.formatMessage({ id: "slider.slide3.description" })
        }
    ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    // Otomatik kaydırma
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prevSlide) => 
                prevSlide === sliderContent.length - 1 ? 0 : prevSlide + 1
            );
        }, 5000); // Her 5 saniyede bir kayar

        return () => clearInterval(timer);
    }, []);

    // Manuel kaydırma fonksiyonları
    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStart - touchEnd > 75) {
            // Sola kaydırma
            setCurrentSlide(current => 
                current === sliderContent.length - 1 ? 0 : current + 1
            );
        }

        if (touchStart - touchEnd < -75) {
            // Sağa kaydırma
            setCurrentSlide(current => 
                current === 0 ? sliderContent.length - 1 : current - 1
            );
        }
    };


    return (
        <>
            <div className="slider-container"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <div className="slider-content">
                    <h2 className="title">
                        <FormattedMessage id="slider.title.home" />
                        <i className="feather-check check-icon"></i>
                    </h2>
                    <h3 className="subtitle">
                        <FormattedMessage id="slider.subtitle" />
                    </h3>
                    
                    <div className="slider-wrapper">
                        <div className="image-container" 
                             style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                            {sliderContent.map((slide, index) => (
    <div key={index} className={`slide ${currentSlide === index ? 'active' : ''}`}>
        <img 
            src={slide.image.src} 
            alt={slide.title}
            style={{
                animationDelay: `${index * 0.2}s`  // Her resime farklı başlangıç zamanı
            }}
        />
        <h4 className="feature-title">
            {slide.title}
            <i className="feather-check check-icon"></i>
        </h4>
        <p className="description">{slide.description}</p>
    </div>
))}

                        </div>
                    </div>
                    
                    <div className="navigation-dots">
                        {sliderContent.map((_, index) => (
                            <span 
                                key={index}
                                className={`dot ${currentSlide === index ? 'active' : ''}`}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <style jsx>{`
    @keyframes breathe {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }

    .slider-wrapper {
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .image-container {
        display: flex;
        transition: transform 0.5s ease-in-out;
        width: 100%;
        margin: 3rem 0;
        position: relative;
    }

    .image-container::after {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 40px;
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 70%);
        filter: blur(8px);
    }

    .slide {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .slide.active {
        opacity: 1;
    }

    .slide img {
        max-width: 70%;
        height: auto;
        border-radius: 16px;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        animation: breathe 3s ease-in-out infinite;
    }

    .slide img:hover {
        animation: none;
        transform: translateY(-4px);
    }

    .slider-container {
        background: linear-gradient(145deg, #ffffff, #f5f7ff);
        border-radius: 24px;
        text-align: center;
        margin: 24px 0;
        padding: 32px;
        position: relative;
        overflow: hidden;
    }

    .slider-container::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
    }

    .slider-content {
        max-width: 800px;
        margin: 0 auto;
        position: relative;
    }

    .title {
        font-size: 2.75rem;
        font-weight: 800;
        margin-bottom: 1.25rem;
        color: #111827;
        letter-spacing: -0.025em;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    .subtitle {
        font-size: 2rem;
        margin-bottom: 2.5rem;
        color: #4f46e5;
        font-weight: 600;
    }

    .feature-title {
        font-size: 1.75rem;
        font-weight: 700;
        margin: 2rem 0 1rem;
        color: #111827;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .check-icon {
        color: #4f46e5;
        font-size: 1.25rem;
    }

    .description {
        font-size: 1.1rem;
        line-height: 1.7;
        color: #4b5563;
        max-width: 600px;
        margin: 0 auto;
        padding: 0 16px;
    }

    .navigation-dots {
        display: flex;
        justify-content: center;
        gap: 12px;
        margin-top: 3rem;
    }

    .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #e5e7eb;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .dot:hover {
        background: #9ca3af;
    }

    .dot.active {
        background: #4f46e5;
        transform: scale(1.2);
    }

    /* Touch navigation styles */
    .slider-container::before,
    .slider-container::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 50px;
        height: 50px;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;
    }

    .slider-container::before {
        left: 10px;
    }

    .slider-container::after {
        right: 10px;
    }

    /* Responsive styles */
    @media (max-width: 768px) {
        @keyframes breathe {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.03);
            }
            100% {
                transform: scale(1);
            }
        }

        .slide img {
            animation-duration: 4s;
        }

        .slider-container {
            padding: 32px 16px;
            margin: 16px;
        }

        .title {
            font-size: 2rem;
        }

        .subtitle {
            font-size: 1.5rem;
        }

        .feature-title {
            font-size: 1.5rem;
        }

        .description {
            font-size: 1rem;
        }
    }
`}</style>

        </>
    );
};

export default MediumBottom;
