import React, { useRef,useState, useEffect,useContext } from 'react';
import Image from "next/image";
import Link from "next/link";
import sal from "sal.js";
import boxedLogo from "../../public/images/logo/logo.png";
import playStore from "../../public/images/sign-up/play-store.svg";
import IosStore from "../../public/images/sign-up/ios-app-store.svg";
import { useRouter } from 'next/router';
import useList from '@/components/isAuth/isAuth';
import Info from  '@/components/Info/Info';
import { FormattedMessage,useIntl  } from 'react-intl';
import { LineWave } from 'react-loader-spinner'

import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact tema CSS
import 'primereact/resources/primereact.min.css';         // PrimeReact temel CSS
import 'primeicons/primeicons.css';  

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

export const useAppContext = () => {
  const [toggleAuth, setToggleAuth] = useState(false); // Varsayılan değer olarak false kullanılabilir
  return { toggleAuth, setToggleAuth };
}

const UserAuth = ({messages}) => {

  const toast = useRef(null);
  const intl = useIntl();
  const list = useList();
  const { toggleAuth, setToggleAuth } = useAppContext();
  const router = useRouter();
  const [loading,setLoading] = useState(false)
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");

 
  const handleCloseInfo = () => {
    setShowInfo(false);
  };
  useEffect(() => {
    sal();
    

    const cards = document.querySelectorAll(".bg-flashlight");
    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft;
        let y = e.pageY - bgflashlight.offsetTop;

        bgflashlight.style.setProperty("--x", x + "px");
        bgflashlight.style.setProperty("--y", y + "px");
      };
    });
  }, []);
  const [resetPassword, setResetPassword] = useState(false); // Şifre Sıfırlama
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [mailSend, setMailSend] = useState(false);
  
  const resetMail = async (e) => {
    e.preventDefault();
    setLoading(true)
    console.log(email)
  
    const response = await fetch('/api/forgot_password/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email })
    });
    const data = await response.json();
    console.log(data);
    if(data.message === "Başarılı"){
      toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "toast.success" }), detail:intl.formatMessage({ id: "sendforgetmail" }) });
      setMailSend(true)
      setLoading(false)
    }
    else{
      toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:data.message });
      setLoading(false)
    }
    
};
  const handleSignInSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      const response = await fetch('/api/signin/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password })
      });
      
      const data = await response.json();
  
      if(data.success){
        localStorage.setItem('mytoken' , data.mytoken)
        if (window.fbq) {
          window.fbq('track', 'CompleteRegistration');
        }
        setLoading(false)
       /*  if(window !== undefined){
          window.location.href = "/"
        } */
        
        router.push("/chat")
      }else{

        toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:data.message });
        setLoading(false)

      }   
      
  }catch (error) {
      console.error('Error:', error);
      setLoading(false)


    }
  }


  
  const handleSignUpSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();

    if (password !== confirmPassword) {
     
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.same" }) });
      setLoading(false)

    }else if (email.trim() === '' || password.trim() === '') {
      
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.email" }) });
      setLoading(false)

    }else if (password.trim() === '') {
      
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.pass" }) });
      setLoading(false)

    }else if (name.trim().length<5){
    
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.error.username" }) });
      setLoading(false)

    }else if(/^\d+$/.test(name)){

      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.name" }) });
      setLoading(false)

    }else if (password.length<5){
   
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.short" }) });
      setLoading(false)

    }else if(/^\d+$/.test(password)){
  
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.letters" }) });
      setLoading(false)

    }else if(/^\d+$/.test(phone)){
    
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:intl.formatMessage({ id: "toast.warning.phone" }) });

      setLoading(false)

    }
  
  else{
    try {
      const response = await fetch('/api/signup/', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, password })
      });

      const data = await response.json();

      // Django'dan gelen cevabı konsola yazdır
      if(data.success){
    
        toast.current.show({ severity: 'success', summary: intl.formatMessage({ id: "toast.success" }), detail:data.message });

        setTimeout(() => {
          window.location.href = "/AuthPage";
        }, 2000); // 3 saniye bekledikten sonra yönlendirme
          
        setLoading(false)
      }else{
    
        toast.current.show({ severity: 'warn', summary:intl.formatMessage({ id: "toast.warning" }), detail:data.message });
        setLoading(false)
      }
    } catch (error) {
      console.error('Error:', error);
      toast.current.show({ severity: 'error', summary: intl.formatMessage({ id: "toast.error" }), detail:error });
      setLoading(false)

    }
  }
  };

  const responseGoogle = (response) => {
    console.log(response);  // Gelen yanıtı burada işleyebilirsiniz.
    const { credential } = response;
    setLoading(true)
  // Bu token'ı backend'e gönderin
    fetch('/api/google_login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: credential,  // Backend'e gönderdiğiniz token
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if(data.success){
          console.log(data)
          localStorage.setItem('mytoken' , data.mytoken)
          if (window.fbq) {
            window.fbq('track', 'CompleteRegistration');
          }
          setLoading(false)
         /*  if(window !== undefined){
            window.location.href = "/"
          } */
          
          router.push("/chat")
        }else{
  
          toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:data.message });
          setLoading(false)
  
        }  

        
        console.log('Backend response:', data);
        // Backend'ten gelen cevaba göre işlemleri yapabilirsiniz
      })
      .catch((err) => {
        console.error('Error during authentication:', err);
      });
    
  };
  const responseFailGoogle = (response) => {
    console.log(response);  // Gelen yanıtı burada işleyebilirsiniz.
    
  };


  const resendVerificationEmail = async () => {
    if (email.trim() === ''){
    
      toast.current.show({ severity: 'warn', summary: intl.formatMessage({ id: "toast.warning" }), detail:'Please Fill The E-Mail İnput' });
    }else{

      setLoading(true);
      try {
        const response = await fetch('/api/send_verification_email_endpoint/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        
        const data = await response.json();

        toast.current.show({ severity: 'success', summary:intl.formatMessage({ id: "toast.success" }), detail:data.message });
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
      
        toast.current.show({ severity: 'error', summary:intl.formatMessage({ id: "toast.error" }), detail:'The email could not be sent.' });
        setLoading(false);
      }
    }
    
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // Enter tuşu basıldığında form submit edilmesi için
      e.preventDefault(); // Şifremi Unuttum linkine tıklamayı engelle
      handleSignInSubmit(e);
    }
  };
  

  return (
    <>
   {/*    <PageHead title={`${toggleAuth ? "Log In" : "SignUp"}`} /> */}
      <div className="signup-area rainbow-section-gapTop-big" data-black-overlay="2">
      <style>
      {`
        .S9gUrf-YoZ4jf {
          
          display:flex;
          justify-content:center;
        }
      `}
        </style>
      <Info message={infoMessage} show={showInfo} onClose={handleCloseInfo} />
      <Toast ref={toast} />
        <div className="sign-up-wrapper rainbow-section-gap">
          <div className="sign-up-box bg-flashlight">
            <div className="signup-box-top top-flashlight light-xl">
              <Image
                  className="logo-light"
                  src={boxedLogo}
                  width={476}
                  height={158}
                  alt="LlmWizard Logo"
                />
            </div>
            <div className="separator-animated animated-true"></div>
            <div className="signup-box-bottom">
              <div className="signup-box-content">
                <h4 className="title">{resetPassword ? <FormattedMessage id="reset.password" />: !toggleAuth ? (<FormattedMessage id="SignIn.title" />):(<FormattedMessage id="SignUp" />)}</h4>
      
                {resetPassword ? (<form onSubmit={resetMail}>
                                   
                                      <div className="input-section mail-section">
                                        <div className="icon">
                                          <i className="feather-mail"></i>
                                        </div>
                                        <input type="email" placeholder={intl.formatMessage({ id: "SignUp.email" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                                      </div>
                                      <button disabled={mailSend || loading} style={{justifyContent:'center',alignItems:'center',display:'flex', background: mailSend ? "#73777b" : "linear-gradient(94deg,#123e7b 10.66%,#3b7beb 53.03%,#ffbd4c 96.34%,#3b7beb 191.41%,#3b7beb 191.43%)"}} type="submit" className="btn-default">
                                       {
                                        loading ?
                                        ( <LineWave
                                          visible={true}
                                          height="100"
                                          width="100"
                                          color="#3D86FE"
                                          ariaLabel="line-wave-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          firstLineColor=""
                                          middleLineColor=""
                                          lastLineColor=""
                                          />)
                                        :
                                                <span><FormattedMessage id="send" /></span>
                                       }
                                      </button>
                                      </form>) : toggleAuth ? (


                  <form onSubmit={handleSignUpSubmit}>
                                      
                  <div className="input-section mail-section">
                    <div className="icon">
                      <i className="feather-user"></i>
                    </div>
                    <input type="text" placeholder={intl.formatMessage({ id: "SignUp.name" })} value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="input-section mail-section">
                    <div className="icon">
                      <i className="feather-mail"></i>
                    </div>
                    <input type="email" placeholder={intl.formatMessage({ id: "SignUp.email" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  {/* <div className="input-section mail-section">
                    <div className="icon">
                      <i className="feather-phone"></i>
                    </div>
                    <input type="text" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </div> */}
                  <div className="input-section password-section">
                    <div className="icon">
                      <i className="feather-lock"></i>
                    </div>
                    <input type="password" placeholder={intl.formatMessage({ id: "SignUp.password" })} value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="input-section password-section">
                    <div className="icon">
                      <i className="feather-lock"></i>
                    </div>
                    <input type="password" placeholder={intl.formatMessage({ id: "SignUp.confirm" })} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  </div>
                  <button style={{justifyContent:'center',alignItems:'center',display:'flex'}} type="submit" className="btn-default">
                   {
                    loading ?
                    ( <LineWave
                      visible={true}
                      height="100"
                      width="100"
                      color="#3D86FE"
                      ariaLabel="line-wave-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                      />)
                    :
                            ( <FormattedMessage id="SignUp" />)
                   }
                  </button>

                  </form>

                ) : (
                  <form onSubmit={handleSignInSubmit}>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input type="email" placeholder={intl.formatMessage({ id: "SignIn.mail" })} value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown}/>
                    </div>
                    <div className="input-section password-section">
                      <div className="icon">
                        <i className="feather-lock"></i>
                      </div>
                      <input type="password" placeholder={intl.formatMessage({ id: "SignIn.password" })}  value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown}/>
                    </div>
                    <div className="forget-text">
                     
                        <button style={{border:"none",padding:"0px",borderBottom:"1px solid #000"}} onClick={() => setResetPassword(true)}><FormattedMessage id="SignIn.forgot" /></button>
             
                    </div>
                    <button style={{justifyContent:'center',alignItems:'center',display:'flex'}} type="submit" className="btn-default" >
                    {
                    loading ?
                    ( <LineWave
                      visible={true}
                      height="100"
                      width="100"
                      color="#3D86FE"
                      ariaLabel="line-wave-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      firstLineColor=""
                      middleLineColor=""
                      lastLineColor=""
                      />)
                    :
                            ( <FormattedMessage id="SignIn" />)
                   }
                    </button>
                  </form>
                )}
              </div>
              <div className="signup-box-footer">
                <div className="bottom-text">
                    <FormattedMessage id="SignIn.under" />
                  <Link className="btn-read-more ps-2" href={toggleAuth ? "" : "#"} onClick={() => {setResetPassword(false); setToggleAuth(!toggleAuth);}}>
                    {toggleAuth ? <span ><FormattedMessage id="SignIn" /></span> : <span > <FormattedMessage id="SignUp" /></span>}
                  </Link> 
                </div>
                <div className="flex justify-center space-x-4 mt-4">
            {/*   <button 
                className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-50"
                style={{
                  display:"flex",
                  flexDirection:"row",
                  gap:"2px"
                }}
              >
                <Image src={playStore} alt="Google Play" className="mr-2" />
              </button> */}
              <GoogleOAuthProvider clientId="636611678414-93fkopuduuvvh9scjn5ch5048qhiiu3b.apps.googleusercontent.com" >
              <div  style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                <h3>Google Login</h3>
              <GoogleLogin  onSuccess={responseGoogle} onError={responseFailGoogle}  />
              </div>
            </GoogleOAuthProvider>
             {/*  <button  className="flex items-center px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                <Image src={IosStore} alt="App Store" className="mr-2" />
              </button> */}
              </div>
                {toggleAuth && (
                  
                <div style={{ marginTop: "20px" }}>
                  <span><FormattedMessage id="verification" /></span>
                  <button className="btn-read-more" style={{border:'none'}} onClick={resendVerificationEmail}>
                    {loading ? 'Sending...' :   <Link className="btn-read-more" href="#">
                        <span><FormattedMessage id="Resend" /></span>
                      </Link>}
                  </button>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuth;
