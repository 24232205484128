import { FormattedMessage } from 'react-intl';

const Analyze = () => {
  return (
    <div className="container">
      <h1>
        <FormattedMessage 
          id="analyze.title" 
          defaultMessage="Analyze Any Type of Content" 
        />
      </h1>
      
      <div className="content-types">
        <div className="content-card">
          <div className="icon-wrapper">
            <i className="feather-file-text"></i>
          </div>
          <h3>
            <FormattedMessage 
              id="analyze.documents.title" 
              defaultMessage="Documents & PDFs" 
            />
          </h3>
          <p>
            <FormattedMessage 
              id="analyze.documents.description" 
              defaultMessage="Upload any document and get instant insights, summaries, and answers to your questions." 
            />
          </p>
          <div className="example">
            <FormattedMessage 
              id="analyze.documents.example" 
              defaultMessage='"Analyze our quarterly reports and highlight key trends"' 
            />
          </div>
        </div>

        <div className="content-card">
          <div className="icon-wrapper">
            <i className="feather-video"></i>
          </div>
          <h3>
            <FormattedMessage 
              id="analyze.media.title" 
              defaultMessage="Video & Audio" 
            />
          </h3>
          <p>
            <FormattedMessage 
              id="analyze.media.description" 
              defaultMessage="Extract insights from video content and audio recordings with AI-powered analysis." 
            />
          </p>
          <div className="example">
            <FormattedMessage 
              id="analyze.media.example" 
              defaultMessage='"Transcribe this meeting recording and create action items"' 
            />
          </div>
        </div>

        <div className="content-card">
          <div className="icon-wrapper">
            <i className="feather-image"></i>
          </div>
          <h3>
            <FormattedMessage 
              id="analyze.images.title" 
              defaultMessage="Images & Photos" 
            />
          </h3>
          <p>
            <FormattedMessage 
              id="analyze.images.description" 
              defaultMessage="Get detailed analysis and descriptions of any image or visual content." 
            />
          </p>
          <div className="example">
            <FormattedMessage 
              id="analyze.images.example" 
              defaultMessage='"What ingredients can you identify in this food photo?"' 
            />
          </div>
        </div>
      </div>

      <div className="ai-models">
        <div className="models-header">
          <h2>
            <FormattedMessage 
              id="analyze.models.title" 
              defaultMessage="Chat with Multiple AI Models" 
            />
          </h2>
          <div className="models-badges">
            <span className="badge gpt4">GPT-4</span>
            <span className="badge claude">Claude 3.5</span>
            <span className="badge gemini">Gemini Pro</span>
          </div>
        </div>

        <div className="features">
          <div className="feature-card">
            <i className="feather-message-circle"></i>
            <h3>
              <FormattedMessage 
                id="analyze.features.conversations.title" 
                defaultMessage="Natural Conversations" 
              />
            </h3>
            <p>
              <FormattedMessage 
                id="analyze.features.conversations.description" 
                defaultMessage="Chat naturally with AI about any topic, from recipe suggestions to business strategy." 
              />
            </p>
          </div>

          <div className="feature-card">
            <i className="feather-monitor"></i>
            <h3>
              <FormattedMessage 
                id="analyze.features.compare.title" 
                defaultMessage="Compare Responses" 
              />
            </h3>
            <p>
              <FormattedMessage 
                id="analyze.features.compare.description" 
                defaultMessage="Get multiple perspectives by comparing answers from different AI models." 
              />
            </p>
          </div>

          <div className="feature-card">
            <i className="feather-settings"></i>
            <h3>
              <FormattedMessage 
                id="analyze.features.custom.title" 
                defaultMessage="Custom Instructions" 
              />
            </h3>
            <p>
              <FormattedMessage 
                id="analyze.features.custom.description" 
                defaultMessage="Tailor each AI model's behavior to match your specific needs and preferences." 
              />
            </p>
          </div>
        </div>
      </div>
  
        <style jsx>{`
          .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 40px 20px;
          }
  
          h1 {
            text-align: center;
            font-size: 3rem;
            margin-bottom: 60px;
          }
  
          .content-types {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            margin-bottom: 80px;
          }
  
          .content-card {
            background: #fff;
            padding: 30px;
            border-radius: 12px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          }
  
          .icon-wrapper {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
          }
  
          .icon-wrapper i {
            font-size: 24px;
          }
          .feather-message-circle h3 {
            color: #4285f4;
            margin-top:15px;
          }
          .content-card:nth-child(1) .icon-wrapper {
            background: rgba(66, 133, 244, 0.1);
            color: #4285f4;
          }
  
          .content-card:nth-child(2) .icon-wrapper {
            background: rgba(149, 97, 226, 0.1);
            color: #9561e2;
          }
  
          .content-card:nth-child(3) .icon-wrapper {
            background: rgba(72, 187, 120, 0.1);
            color: #48bb78;
          }
  
          .example {
            margin-top: 20px;
            padding: 15px;
            background: #f8fafc;
            border-radius: 8px;
            font-size: 1.1rem;
            
            color: #000;
          }
  
          .ai-models {
            background: #fff;
            border-radius: 20px;
            padding: 40px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
          }
  
          .models-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
          }
  
          .models-badges {
            display: flex;
            gap: 10px;
          }
  
          .badge {
            padding: 10px 18px;
            border-radius: 20px;
            font-size: 1.2rem;
          }
  
          .badge.gpt4 {
            background: rgba(66, 133, 244, 0.1);
            color: #4285f4;
          }
  
          .badge.claude {
            background: rgba(149, 97, 226, 0.1);
            color: #9561e2;
          }
  
          .badge.gemini {
            background: rgba(72, 187, 120, 0.1);
            color: #48bb78;
          }
  
          .features {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
          }
  
          .feature-card {
            padding: 20px;
            background: #f8fafc;
            border-radius: 12px;
          }
  
          .feature-card:nth-child(1) i {
            color: #4285f4;
          }
  
          .feature-card:nth-child(2) i {
            color: #9561e2;
          }
  
          .feature-card:nth-child(3) i {
            color: #48bb78;
          }
  
          .feature-card i {
            margin-bottom: 15px;
            font-size: 24px;
          }
  
          h2 {
            font-size: 1.5rem;
            margin: 0;
          }
  
          h3 {
            font-size: 1.2rem;
            margin-bottom: 15px;
          }
  
          p {
            color: #64748b;
            line-height: 1.5;
          }
        `}</style>
      </div>
    );
  };
  
  export default Analyze;
  