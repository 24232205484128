import Header from "@/components/Header/Header";
import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Context from "@/context/Context";
import Footer from "@/components/Footer/Footer";
import UserAuth from "@/components/Auth/UserAuth";

import PageHead from "../Head";

const AuthPage = ( {handleLanguageChange}) => {
  return (
    <>
   
   <PageHead title="AuthPage" />
      <main className="page-wrapper">
        <Context>
          {/*<HeaderTop />*/}
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
            handleLanguageChange={handleLanguageChange}
          />
          <PopupMobileMenu />

          <UserAuth />

          <Footer />
 
        </Context>
      </main>
    </>
  );
};

export default AuthPage;
