import { useEffect, useState  } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";
import useList from '@/components/isAuth/isAuth';
import logo from "../../public/images/logo/logo.png";
import Nav from "./Nav";
import Select from "react-select";
import { FormattedMessage ,useIntl } from 'react-intl';
import flag from "../../public/images/icons/en-us.png";
import flag2 from "../../public/images/icons/turkey.png";

const Header = ({ headerTransparent, headerSticky, btnClass, fix ,handleLanguageChange}) => {
  const list = useList();  // useList hook'unu kullanarak listeyi çekiyoruz
  const { activeMobileMenu, setActiveMobileMenu } = useAppContext();

  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState(null);  // Kullanıcı durumu için state

  // API'ye fetch isteği atan fonksiyon
  const sendUserId = async (user_id) => {
    try {
      const response = await fetch('/api/user_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id }), // user_id olarak list[3]'ü gönderiyoruz
      });

      if (response.ok) {
        const data = await response.json();
     
        setPaymentStatus(data.user_status);  // API'den gelen status bilgisi
      } else {
        console.error("API isteğinde hata:", response.statusText);
      }
    } catch (error) {
      console.error("API isteği başarısız oldu:", error);
    }
  };
 
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 200) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      setIsSticky(currentScrollY > 200);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    // Liste dolu ve en az 4 eleman varsa list[3] elemanını alıp API'ye gönderiyoruz
    if (list && list.length > 3) {
      const userId = list[3];  // list[3] elemanını alıyoruz
      sendUserId(userId);  // user_id'yi API'ye gönderiyoruz
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, list]);  // list ve lastScrollY değiştiğinde bu effect tetiklenir

  const headerStyle = {
    transition: "top 0.3s",
    top: isVisible ? "0" : "-100px", // Adjust -100px based on your header height
    position: isSticky ? "fixed" : "relative",
    width: "100%",
    zIndex: 1000, // Ensure the header stays on top
  };


  
  const intl = useIntl();
  const language = intl.locale;
  const context = useAppContext() || {}; // useAppContext undefined dönerse boş obje kullanılır.
  const { shouldCollapseLeftbar = false } = context;

  const LanguageOptions = [
    { value: "en", label: "En", icon: flag },
    { value: "tr", label: "Tr", icon: flag2 },

    
  ];
  const temp = LanguageOptions.find(option => option.value === language);

  const [languageLocal, setLanguage] = useState(temp);

  const handleChangeLanguage = (selectedOption) => {
    setLanguage(selectedOption); // Seçilen dilin durumunu güncelle
    handleLanguageChange(selectedOption.value); // Seçilen dilin değerini ileti
  };

  const formatOptionLabel = ({ value, label, icon }) => (
    <div className="">
      <Image
        className="left-image"
        src={icon}
        alt={value}
        width={20}
        height={20}
      />
      <span style={{ marginLeft: "10px" }}>{label}</span>
    </div>
  );

  return (
    <>
      {paymentStatus === "UNPAID" && (
        <div style={{ backgroundColor: 'red', color: 'white', padding: '10px', textAlign: 'center' }}>
          <strong>Ödeme başarısız oldu</strong>
          <p>
          <FormattedMessage id="warning-payment1" /> <Link href="/profile-details" legacyBehavior><a style={{ color: 'white', textDecoration: 'underline' }}><FormattedMessage id="warning-payment2" /> </a></Link> 
          <FormattedMessage id="warning-payment3" />
          </p>
        </div>
      )}

      <header
        className={`rainbow-header header-default ${headerTransparent} ${headerSticky} ${
          isSticky ? "sticky" : ""
        }`}
        style={{position:`${fix}`}}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="logo" style={{display:"flex"}}>
                <Link href="/">
               <Image
                  className="logo-light"
                  src={logo}
                  width={150}
                  height={35}
                  alt="LlmWizard Logo"
                />
                </Link>
                <div className="rbt-modern-select  " style={{display:"flex",alignItems:"center",width:"20%",background:"transparent"}}>
                <Select
                  instanceId="sortByAuthor"
                  className="bootstrap-select"
                  classNamePrefix="bootstrap-select"
                  defaultValue={languageLocal}
                  onChange={handleChangeLanguage}
                  options={LanguageOptions}
                  formatOptionLabel={formatOptionLabel}
                  closeMenuOnSelect={true}
                  maxMenuHeight={150}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: 150,  // Kontrol bileşeninin genişliği
                      borderRadius: '8px',
                      borderColor: '#ced4da',
                      color: 'var(--color-heading)',  // Varsayılan değerin metin rengini siyah yapıyoruz
                      backgroundColor: 'transparent !important' ,  // Arka plan rengini de beyaz yapıyoruz
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'var(--color-heading)',  // Seçili olan değerin rengini siyah yapıyoruz
                    }),
                    menu: (provided) => ({
                      ...provided,
             
                      zIndex: 100,
                      width: 150,
                      backgroundColor:"transparent",
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: 150,
                      minWidth:50,
                      width: 130,
                    }),
                    option: (provided) => ({
                      ...provided,
                      width: 120,
                      minWidth:50,
                    }),
                  }}
  
                />
            
                 
           
        </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-6 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <button
                      className="hamberger-button"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <i className="feather-menu"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
