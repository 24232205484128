import React, { useEffect, useRef,useState } from "react";
import { FormattedMessage ,useIntl  } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/llm-home/nova_explosion.png";
import research_3 from "../../public/images/llm-home/research-3.png";
import oldcar from "../../public/images/llm-home/oldcar.png";

import Image from "next/image";


import useList from '@/components/isAuth/isAuth';


const FAQ = () => {
    const list = useList();
    const intl = useIntl();
    const userID = list[0];
    const [activeTab, setActiveTab] = useState('create');

    // Her sekme için resim tanımlama
    const tabImages = {
        analyze: llms,
        create: research_3,
        compare: oldcar
    };

    const tabContent = {
        analyze: {
            title: intl.formatMessage({ id: "faq.analyze.title" }),
            subtitle: intl.formatMessage({ id: "faq.analyze.subtitle" }),
            options: [
                intl.formatMessage({ id: "faq.analyze.option1" }),
                intl.formatMessage({ id: "faq.analyze.option2" }),
                intl.formatMessage({ id: "faq.analyze.option3" }),
                intl.formatMessage({ id: "faq.analyze.option4" }),
                intl.formatMessage({ id: "faq.analyze.option5" })
            ]
        },
        create: {
            title: intl.formatMessage({ id: "faq.create.title" }),
            subtitle: intl.formatMessage({ id: "faq.create.subtitle" }),
            options: [
                intl.formatMessage({ id: "faq.create.option1" }),
                intl.formatMessage({ id: "faq.create.option2" }),
                intl.formatMessage({ id: "faq.create.option3" }),
                intl.formatMessage({ id: "faq.create.option4" }),
                intl.formatMessage({ id: "faq.create.option5" })
            ]
        },
        compare: {
            title: intl.formatMessage({ id: "faq.compare.title" }),
            subtitle: intl.formatMessage({ id: "faq.compare.subtitle" }),
            options: [
                intl.formatMessage({ id: "faq.compare.option1" }),
                intl.formatMessage({ id: "faq.compare.option2" }),
                intl.formatMessage({ id: "faq.compare.option3" }),
                intl.formatMessage({ id: "faq.compare.option4" }),
                intl.formatMessage({ id: "faq.compare.option5" })
            ]
        }
    };

    return (
        <>
               <div className="container1">
            <h1>
                <FormattedMessage id="faq.title" />
            </h1>
            <p className="subtitle">
                <FormattedMessage id="faq.subtitle" />
            </p>
            
            <div className="tabs">
                <button 
                    className={`tab ${activeTab === 'analyze' ? 'active' : ''}`}
                    onClick={() => setActiveTab('analyze')}
                >
                    <FormattedMessage id="faq.tab.analyze" />
                </button>
                <button 
                    className={`tab ${activeTab === 'create' ? 'active' : ''}`}
                    onClick={() => setActiveTab('create')}
                >
                    <FormattedMessage id="faq.tab.create" />
                </button>
                <button 
                    className={`tab ${activeTab === 'compare' ? 'active' : ''}`}
                    onClick={() => setActiveTab('compare')}
                >
                    <FormattedMessage id="faq.tab.compare" />
                </button>
            </div>

            <div className="content-wrapper">
                    <div className="image-section">
                        <Image 
                            src={tabImages[activeTab]} 
                            alt={`${activeTab} Visualization`} 
                            className="circular-image" 
                        />
                        <button className="create-button">
                            <FormattedMessage id="faq.button.create" />
                        </button>
                    </div>

                <div className="options-section">
                    <h2>{activeTab.toUpperCase()}</h2>
                    <h3>
                        <FormattedMessage id={`faq.${activeTab}.title`} />
                    </h3>
                    <p>
                        <FormattedMessage id={`faq.${activeTab}.subtitle`} />
                    </p>
                    <div className="options-list">
                        {tabContent[activeTab].options.map((option, index) => (
                            <Link href="#" className="option-item" key={index}>
                                <span className="option-text">{option}</span>
                                <i className="feather-arrow-right"></i>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>

            <style jsx>{`
                .container1 {
        max-width: 1200px;
        margin: 0 auto;
        padding: 2rem;
        margin-top: 5rem;
    }

    h1 {
    text-align: center;
    font-size:4rem;  /* Increased from 2.5rem */
    margin-bottom: 1rem;
    font-weight: 700;
}

.subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
    font-size: 1.4rem;  /* Increased from 1.1rem */
}

.tab {
    padding: 1rem 2rem;
    border: none;
    border-radius: 20px;
    background: #f5f5f5;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1.4rem; /* Decreased from 2rem */
    margin: 2rem 0; /* Added margin for spacing */
}

.tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 3rem 0; /* Increased from 2rem */
    padding: 1rem 0; /* Added padding for extra spacing */
}


h2 {
    font-size: 1.5rem;  /* Increased from 1.2rem */
    color: #4758FF;
    margin-bottom: 0.5rem;
}

h3 {
    font-size: 3rem;  /* Increased from 1.8rem */
    margin-bottom: 1rem;
    font-weight: 700;
}

.option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    background: #f5f5f5;
    border-radius: 12px;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
    font-size: 1.6rem;
    gap: 1rem; /* Add gap between text and arrow */
}

.arrow {
    margin-left: auto; /* Push arrow to the right */
    padding-left: 1rem; /* Add padding to separate from text */
    font-size: 1.3rem;
    font-weight: bold;
}

.option-text {
    flex: 1;
    font-weight: 500;
    padding-right: 1rem; /* Add padding to separate from arrow */
}


/* Updated responsive styles */
@media (max-width: 768px) {
    h1 {
        font-size: 2.8rem;
    }

    .subtitle {
        font-size: 1.3rem;
    }

    .tab {
        font-size: 1.2rem;
    }

    .option-item {
        font-size: 1.3rem;
    }

    h3 {
        font-size: 2rem;
    }
}

    .subtitle {
        text-align: center;
        color: #666;
        margin-bottom: 2rem;
        font-size: 2rem;
    }

 


    .tab:hover {
        background: #e0e0e0;
    }

    .tab.active {
        background: #4758FF;
        color: white;
    }

    .content-wrapper {
    display: flex;
    gap: 4rem; /* Increased from 2rem */
}


    .image-section {
        flex: 1;
        position: relative;
        border-radius: 25px;  /* Added border-radius to container */
        overflow: hidden;     /* Ensure child image respects border-radius */
    }

    .circular-image {
        width: 90%;
        height: auto;
        border-radius: 25px;  /* Increased border-radius */
        transition: transform 0.3s ease;
    }

    .circular-image:hover {
        transform: scale(1.02);
    }

    .create-button {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: 0.8rem 1.5rem;
        background: rgba(255, 255, 255, 0.9);
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 1.1rem;  /* Added font-size */
    }

    .create-button:hover {
        background: rgba(255, 255, 255, 1);
        transform: translateY(-2px);
    }

    .options-section {
        flex: 1;
        width:100%;
        padding-left:10px;

    }

    .options-list {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-left:25px;

        flex:1;
        width:100%;
    }

 


    .option-item:hover {
        background: #eee;
        transform: translateX(5px);
    }

 



    /* Added responsive styles */
    @media (max-width: 768px) {
        .content-wrapper {
            flex-direction: column;
        }

        .option-item {
            font-size: 1.1rem;
        }

        h3 {
            font-size: 1.6rem;
        }
    }
            `}</style>
        </>
    );
};

export default FAQ;


