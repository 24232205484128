import React, { useState, useEffect,useRef } from 'react';
import Image from 'next/image';
import assistant from '../../public/images/menu-img/logo_small.png';
import user from '../../public/images/menu-img/user3.png';
import { FormattedMessage, useIntl } from 'react-intl';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import chatgpt from '../../public/images/menu-img/gpta.png';
import gemini from '../../public/images/menu-img/gemini.png';
import claude from '../../public/images/menu-img/2a.jpg';
import meta from '../../public/images/menu-img/meta.png';
import DesignHomeHead from '@/components/DesignHomeHead/DesignHomeHead';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact tema CSS
import 'primereact/resources/primereact.min.css';         // PrimeReact temel CSS
import 'primeicons/primeicons.css';  
const { dracula } = require('react-syntax-highlighter/dist/cjs/styles/prism'); // 'esm' yerine 'cjs' kullanın

const DesignHomeMessage = () => {
  const { formatMessage } = useIntl();
  const intl = useIntl();

const [anyLongMessage, setAnyLongMessage] = useState(false);
const [inputFocused, setInputFocused] = useState(false);
const [inputAnimationState, setInputAnimationState] = useState('initial');
const [showSuggestedQuestions, setShowSuggestedQuestions] = useState(true);



const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const [selectedModel, setSelectedModel] = useState('gpt3.5');

// Dropdown verisi
const models = [
  { id: 'gpt3.5', name: 'GPT-4o-mini', icon: chatgpt },
  { id: 'gpt4', name: 'GPT-4', icon: chatgpt },
  { id: 'claude', name: 'Claude 3.5 Sonnet v2', icon: claude },
  { id: 'gemini', name: 'Gemini 1.5 pro', icon: gemini },
  { id: 'llama', name: 'Llama 3.1 405b ', icon: meta },
];
const ModelDropdown = ({ selectedModel, isOpen, onToggle, models, onSelect }) => {
  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event) => {
        // "dropdown" class'ını doğrudan string olarak kullan
        if (!event.target.closest('.dropdown')) {
          onToggle();
        }
      };
  
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [isOpen, onToggle]);
  return (
    <>
    <style jsx>{`
      .dropdown {
  position: relative;
  z-index: 5;
}

.trigger {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: white;
  border:none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  color: #1e293b;
  transition: all 0.2s;
}

.trigger:hover {
  background: #f8fafc;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.arrow {
  transition: transform 0.2s;
}

.arrow.open {
  transform: rotate(180deg);
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  width: 200px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
  padding: 4px;
  animation: slideDown 0.2s ease;
}

.option {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #1e293b;
  transition: background-color 0.2s;
}

.option:hover {
  background: #f8fafc;
}

.option.active {
  background: #e2e8f0;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
      `}</style>
    <div className={"dropdown"}>
      <button className={"trigger"} onClick={onToggle}>
        <Image 
          src={models.find(m => m.id === selectedModel).icon}
          alt={selectedModel}
          width={24}
          height={24}
          
        />
        <span style={{marginLeft:4}}>{models.find(m => m.id === selectedModel).name}</span>
        <svg 
          className={`${"arrow"} ${isOpen ? "open" : ''}`}
          width="12" 
          height="12" 
          viewBox="0 0 12 12"
          style={{marginLeft:2}}
        
        >
          <path d="M2 4L6 8L10 4" stroke="currentColor" fill="none"/>
        </svg>
      </button>
      
      {isOpen && (
        <div className={"menu"}>
          {models.map((model) => (
            <button
              key={model.id}
              className={`${"option"} ${selectedModel === model.id ? "active" : ''}`}
              onClick={() => onSelect(model.id)}
            >
              <Image src={model.icon} alt={model.name} width={24} height={24} />
              <span>{model.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
    </>

  );
};


const suggestedQuestions = [
  { 
    text: <FormattedMessage id="suggestedQuestion1" defaultMessage="What is LLMWizard?" />, 
    icon: "pi pi-question-circle" 
  },
  { 
    text: <FormattedMessage id="suggestedQuestion2" defaultMessage="How can I use LLMWizard?" />, 
    icon: "pi pi-cog" 
  },
  { 
    text: <FormattedMessage id="suggestedQuestion3" defaultMessage="What are the features?" />, 
    icon: "pi pi-list" 
  },
  { 
    text: <FormattedMessage id="suggestedQuestion4" defaultMessage="How much does it cost?" />, 
    icon: "pi pi-dollar" 
  }
];

const suggestedAnswers = {
  "suggestedQuestion1": intl.formatMessage({
    id: "suggestedAnswer1",
    defaultMessage: "LLMWizard is an advanced AI-powered platform that helps users interact with and utilize large language models effectively."
  }),
  "suggestedQuestion2": intl.formatMessage({
    id: "suggestedAnswer2",
    defaultMessage: "You can use LLMWizard by signing up on our website, choosing a plan, and then accessing our user-friendly interface to start interacting with AI models."
  }),
  "suggestedQuestion3": intl.formatMessage({
    id: "suggestedAnswer3",
    defaultMessage: "LLMWizard offers features such as natural language processing, text generation, language translation, sentiment analysis, and custom model fine-tuning."
  }),
  "suggestedQuestion4": intl.formatMessage({
    id: "suggestedAnswer4",
    defaultMessage: "LLMWizard offers various pricing tiers to suit different needs. Our plans start from $9.99/month for basic usage, with custom enterprise solutions also available."
  })
};

useEffect(() => {
  // Input animasyonu
  setTimeout(() => setInputAnimationState('expanded'), 500);
  setTimeout(() => setInputAnimationState('contracted'), 1500);
  setTimeout(() => setInputAnimationState('normal'), 2500);

  // İlk mesaj animasyonu
  setTimeout(() => {
    setMessages(prevMessages => 
      prevMessages.map((msg, index) => 
        index === 0 ? {...msg, animationState: 'expanded'} : msg
      )
    );
  }, 500);
}, []);
  const [messages, setMessages] = useState([
    {
      text: formatMessage({ id: 'Home.design.text' }),
      sender: 'assistant',
      date: new Date().toLocaleTimeString(),
      name: formatMessage({ id: 'Home.design.assistant' }),
      animationState: 'initial'

    }
  ]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [questionCount, setQuestionCount] = useState(0); // Kullanıcı tarafından sorulan soru sayısı

  useEffect(() => {

    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    console.log(currentHour , currentMinute)
    // Soru sayısı değiştiğinde localStorage'a kaydet
    if(currentHour == 17 && currentMinute==33){
      localStorage.setItem("limiter" , "true")
    }
  }, []);
  
  useEffect(() => {
    // Soru sayısı değiştiğinde localStorage'a kaydet
    const limiter = localStorage.getItem('limiter');
    if (!limiter) {
      // 'limiter' itemini oluştur ve valuesini 'true' yap
      localStorage.setItem('limiter', 'true');
    }

    localStorage.setItem('questionCount', questionCount.toString());
  }, [questionCount]);

  const handleSuggestedQuestionClick = (questionId) => {
    const userMessage = {
      text: intl.formatMessage({ id: questionId }),
      sender: 'user',
      date: new Date().toLocaleTimeString(),
      name: formatMessage({ id: 'Home.design.user' }),
    };
    setMessages(prevMessages => [...prevMessages, userMessage]);
  
    const assistantMessage = {
      text: '',
      sender: 'assistant',
      date: new Date().toLocaleTimeString(),
      name: formatMessage({ id: 'Home.design.assistant' }),
    };
    setMessages(prevMessages => [...prevMessages, assistantMessage]);
  
    let currentText = '';
    let charIndex = 0;
    const answer = intl.formatMessage({ id: `${questionId.replace('Question', 'Answer')}` });
    const intervalId = setInterval(() => {
      if (charIndex < answer.length) {
        currentText += answer[charIndex];
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = {
            ...assistantMessage,
            text: currentText,
          };
          return updatedMessages;
        });
        charIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, 10);
  
    setShowSuggestedQuestions(false);
  };
  
  
  const handleSendMessage = async (message = newMessage) => {
    // Eğer message bir string değilse, newMessage'ı kullan
    const messageToSend = typeof message === 'string' ? message : newMessage;
  
    if (messageToSend && messageToSend.trim()) {
      setShowSuggestedQuestions(false);
  
      const userMessage = {
        text: messageToSend,
        sender: 'user',
        date: new Date().toLocaleTimeString(),
        name: formatMessage({ id: 'Home.design.user' }),
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setNewMessage('');
      setQuestionCount(prevCount => prevCount + 1);
  
      const limiter = localStorage.getItem('limiter');
      if (questionCount < 2 && limiter === 'true') {
        setLoading(true);
        try {
          const response = await fetch('/api/home_gpt', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message: messageToSend }),
          });
  
          const data = await response.json();
  
          const assistantMessage = {
            text: '',
            sender: 'assistant',
            date: new Date().toLocaleTimeString(),
            name: formatMessage({ id: 'Home.design.assistant' }),
          };
  
          setMessages(prevMessages => [...prevMessages, assistantMessage]);
  
          let currentText = '';
          let charIndex = 0;
          const intervalId = setInterval(() => {
            if (charIndex < data.response.length) {
              currentText += data.response[charIndex];
              setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1] = {
                  ...assistantMessage,
                  text: currentText,
                };
                return updatedMessages;
              });
              charIndex++;
            } else {
              clearInterval(intervalId);
            }
          }, 10);
  
        } catch (error) {
          console.error('API error:', error);
        } finally {
          setLoading(false);
        }
      } else {
        const registrationMessage = {
          text: formatMessage({
            id: 'Home.design.registerMessage',
            defaultMessage: "LLMWizard'a kayıt olarak konuşmaya devam edebilirsiniz."
          }),
          sender: 'assistant',
          date: new Date().toLocaleTimeString(),
          name: formatMessage({ id: 'Home.design.assistant' }),
        };
        setMessages(prevMessages => [...prevMessages, registrationMessage]);
      }
  
      if (questionCount >= 1) {
        localStorage.setItem('limiter', 'false');
      }
    }
  };
  const textareaRef = useRef(null);
  useEffect(() => {
     setAnyLongMessage( messages.some(message => message.text.length > 200))


  },[messages])


  useEffect(() => {
    // Ekran genişliğini kontrol et
    const isMobile = window.innerWidth <= 769;

    // Eğer mobil cihaz değilse, textarea'ya odaklan
    if (!isMobile && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);
  return (
    <>
     <DesignHomeHead />
        <div className={`chat-container ${anyLongMessage ? 'large' : ''}`}>
          <div className={`message-container ${anyLongMessage ? 'large' : ''}`}>
          <div style={{ position: 'absolute', right: 20,top:10 }}>
        <ModelDropdown
          selectedModel={selectedModel}
          isOpen={isDropdownOpen}
          onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
          models={models}
          onSelect={(modelId) => {
            setSelectedModel(modelId);
            setIsDropdownOpen(false);
          }}
        />
      </div>
          <div className="gpt-logo-background"></div>

          <div className="message-list">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message-item ${
                  message.sender === 'user' ? 'user-message-item' : 'assistant-message-item'
                } ${message.animationState}`}
              >
                <div className="message-avatar">
                  <Image
                    src={message.sender === 'user' ? user : assistant}
                    alt={message.name}
                    width={40}
                    height={40}
                    className="avatar-image"
                  />
                </div>
                <div className={`message-bubble ${message.sender}`}>
                  <div className="message-header">
                    <span className="message-name">{message.name}</span>
                    <span className="message-date">{message.date}</span>
                  </div>
                  <div className="message-text">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || '');
                          return !inline && match ? (
                            <SyntaxHighlighter
                              style={dracula}
                              language={match[1]}
                              PreTag="div"
                              {...props}
                            >
                              {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          );
                        }
                      }}
                    >
                      {message.text}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {showSuggestedQuestions && (
            <div className="suggested-questions">
             {suggestedQuestions.map((question, index) => (
              <div
                key={index}
                className="suggested-question"
                onClick={() => handleSuggestedQuestionClick(`suggestedQuestion${index + 1}`)}
              >
                <i className={question.icon}></i>
                <span>{question.text}</span>
              </div>
            ))}
            </div>
          )}
          <div className={`message-input-container ${inputAnimationState}`}>
              <input
                ref={textareaRef}
                type="text"
                aria-label='chat box'
                value={newMessage}  
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={formatMessage({ id: 'Home.design.placeholder' })}
                disabled={loading}
              />
              <button
                style={{ flexDirection: 'row', display: 'flex', backgroundColor: loading ? 'gray' : '#123e7b' }}
                onClick={handleSendMessage}
                disabled={loading || newMessage.trim() === ''}
              >
                <div><FormattedMessage id="setup.send" /></div>
                <i style={{ marginLeft: 5 }} className="feather-send"></i>
              </button>
            </div>
          </div>
        </div>
      <style jsx>{`
        html, body {
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f1f5f9;
        }
        .suggested-questions {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          margin-bottom: 20px;
        }

        .suggested-question {
          background-color: #f0f0f0;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          transition: background-color 0.3s;
        }

        .suggested-question:hover {
          background-color: #e0e0e0;
        }

        .suggested-question i {
          margin-right: 10px;
          font-size: 18px;
          color: #123e7b;
        }

        .suggested-question span {
          font-size: 14px;
          color: #333;
        }

        @media (max-width: 768px) {
          .suggested-questions {
            grid-template-columns: 1fr;
          }
        }
        .outer-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          width: 100vw;
        }

        .ask-me-now {
          position: absolute;
          top: -30px;
          font-size: 24px;
          font-weight: bold;
          color: #123e7b;
        }

        .arrow {
          font-size: 30px;
          margin: 0 10px;
        }

        .message-input-container {
          display: flex;
          align-items: center;
          padding-top: 12px;
          padding-left: 0px;
          transition: all 0.5s ease;
        }

        .message-input-container input {
          flex-grow: 1;
          padding: 12px;
          border-radius: 25px;
          border: 1px solid #e2e8f0;
          outline: none;
          padding-left: 20px;
          font-size: 14px;
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
          transition: all 0.5s ease;
        }

        .message-input-container.initial input {
          width: 0;
          padding: 0;
        }

        .message-input-container.expanded input {
          width: 100%;
          padding: 12px 20px;
        }

        .message-input-container.contracted input {
          width: 30%;
          padding: 12px 20px;
        }

        .message-input-container.normal input {
          width: 70%;
          padding: 12px 20px;
        }

        .chat-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 70vh;
          margin:0;
          width: 100vw;
          padding: 20px;          
          transition: height 0.3s ease, width 0.3s ease;
        }

        .chat-container.large {
          height: 90vh;
          width: 100%;
        }

      
        .message-container {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          max-width: 900px;
          min-height: 300px;
          height: 480px;
          border: 1px solid var(--welcome-chat-border);
          border-radius: 16px;
          background: var(--welcome-chat);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
          padding: 20px;
          transition: height 0.3s ease, width 0.3s ease;
          overflow: none;
        }

        .gpt-logo-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-image: url(${assistant.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.1;
    z-index: 0;
    pointer-events: none;
  }
      

        .message-input-container {
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          padding-top: 12px;
          padding-left: 0px;
          transition: all 0.5s ease;
        }

        .message-container.large {
          height: 700px;
          width: 1200px;
        }

        .message-list {
          position: relative;
          z-index: 1;
          flex-grow: 1;
          overflow-y: auto;
          padding-right: 10px;
        }

        .message-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    transition: all 0.5s ease;
  }

  .message-item.initial {
    transform: scale(0);
    opacity: 0;
  }

  .message-item.expanded {
    transform: scale(1);
    opacity: 1;
  }

  .message-bubble {
    padding: 12px 16px;
    border-radius: 24px;
    max-width: 96%;
    word-wrap: break-word;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    line-height: 1.5;
    position: relative;
    transition: all 0.5s ease;
  }

  .message-item.initial .message-bubble {
    transform: scale(0);
  }

  .message-item.expanded .message-bubble {
    transform: scale(1);
  }


        .message-avatar {
          margin-right: 12px;
        }
        @media (max-width: 768px) {
        .chat-container {
          height: 90vh;
          padding: 10px;
        }

        .chat-container.large {
          height: 90vh;
        }

        .message-container {
          height: 100%;
          max-width: 100%;
          border-radius: 0;
        }

        .message-container.large {
          height: 100%;
          width: 100%;
        }

       
        .message-header {
          font-size: 10px;
        }

        .message-text {
          font-size: 13px;
        }

        .message-input-container input {
          font-size: 12px;
        }

        .message-input-container button {
          padding: 10px 16px;
          font-size: 12px;
        }
      }

        .avatar-image {
          border-radius: 50%;
          object-fit: cover;
        }

        .message-bubble {
          padding: 12px 16px;
          border-radius: 24px;
          max-width: 96%;
          word-wrap: break-word;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          font-size: 15px;
          line-height: 1.5;
          position: relative;
        }

        .message-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          font-size: 12px;
          color: #6b7280;
        }

        .message-name {
          font-weight: bold;
          color: #123E7B;
        }

        .message-date {
          color: #123E7B;
        }

        .message-text {
          font-size: 15px;
        }

        .user-message-item .message-bubble {
          background-color: var(--welcome-chatbox-user)
          color: #000000; /* Siyah metin rengi */
          min-width: 200px;
        }

        .assistant-message-item .message-bubble {
          background-color:var(--welcome-chatbox-ai);
          color: #123e7b;
          align-self: flex-start;
          margin-right: auto;
        }

      
        .message-input-container input {
          flex-grow: 1;
          padding: 12px;
          border-radius: 25px;
          border: 1px solid #e2e8f0;
          outline: none;
          padding-left: 20px;
          font-size: 14px;
          box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
        }

        .message-input-container button {
          margin-left: 12px;
          padding: 12px 24px;
          border: none;
          border-radius: 25px;
          background-color: #123e7b;
          color: white;
          font-weight: bold;
          cursor: pointer;
          transition: background-color 0.3s ease, transform 0.2s ease;
        }

        .message-input-container button:hover {
          background-color: #0d2a5a;
          transform: translateY(-2px);
        }

        .message-input-container button:active {
          transform: translateY(1px);
        }

        .message-list::-webkit-scrollbar {
          width: 8px;
        }

        .message-list::-webkit-scrollbar-thumb {
          background-color: #123e7b;
          border-radius: 4px;
        }

        .message-list::-webkit-scrollbar-track {
          background-color: #f1f5f9;
        }
      `}</style>
    </>
  );
};

export default DesignHomeMessage;
