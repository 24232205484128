import Link from "next/link";
import { useEffect, React } from "react";
import { FormattedMessage } from "react-intl";
import "venobox/dist/venobox.min.css";
import Image from "next/image";
import bannerImg from "../../public/images/banner/banner-image-03.png";
import claude from "../../public/images/menu-img/2a.jpg";
import gemini from "../../public/images/menu-img/gemini.png";
import gpt from "../../public/images/menu-img/gpt.png";
import mixtral from "../../public/images/menu-img/mixtral.png";
import gemma from "../../public/images/menu-img/gemma.png";
import meta from "../../public/images/menu-img/meta.png";

const LlmSlider = () => {
  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-12"
              style={{
                display: "flex",
                flex: "0 0 224px",
                width: "100%",
                flexDirection: "column",
                gap: "40px",
                paddingBlock: "72px",
                borderRadius: 18,
              }}
            >
              <div className="slider-content text-center">
                <p
                  className="home-slider-title"
                  style={{
                    fontSize: "clamp(22px, 1.8vw, 40px)",
                    fontFamily: "var(--secondary-font)",
                    marginBottom: 0,
                  }}
                >
                  <FormattedMessage id="slider.title" />
                </p>
                <div
                  className="slider_desc"
                  style={{ fontFamily: "var(--secondary-font)", color: "grey" }}
                >
                  <FormattedMessage id="slider.desc" />
                </div>
              </div>

              <div class="Info-slider" style={{ borderRadius: 18 }}>
                <div className="slide-track">
                  <div className="Info-slide">
                    <Image
                      src={claude}
                      width={50}
                      height={50}
                      alt="Claude Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Claude 3 Opus
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image
                      src={gemini}
                      width={50}
                      height={50}
                      alt="Gemini Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gemini
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gpt 4o-mini
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gpt - 4
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gpt - 4o
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      DALL-E 3
                    </h5>
                  </div>

                  <div className="Info-slide">
                    <Image
                      src={claude}
                      width={50}
                      height={50}
                      alt="Claude Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Claude 3.5 Sonnet
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image
                      src={meta}
                      width={50}
                      height={50}
                      alt="Gemini Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Llama 3.1
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image
                      src={claude}
                      width={50}
                      height={50}
                      alt="Claude Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Claude 3 Opus
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image
                      src={claude}
                      width={50}
                      height={50}
                      alt="Claude Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Claude 3.5 Sonnet
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gpt 4o-mini
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image
                      src={gemini}
                      width={50}
                      height={50}
                      alt="Gemini Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gemini
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Gpt - 4
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image src={gpt} width={50} height={50} alt="Gpt Logo" />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      DALL-E 3
                    </h5>
                  </div>
                  <div className="Info-slide">
                    <Image
                      src={meta}
                      width={50}
                      height={50}
                      alt="Gemini Logo"
                    />

                    <h5 style={{ padding: "0 0 0 5px", margin: "0" }}>
                      Llama 3.1
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LlmSlider;
