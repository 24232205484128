import { FormattedMessage } from 'react-intl';
import useList from '@/components/isAuth/isAuth';
import { useRouter } from 'next/router'; // Add

const ReadyToTransform = () => {
  const router = useRouter(); // Initialize router
  const list = useList();
  const userID = list[0];

  const handleClick = () => {
    if (userID) {
      router.push('/chat');
    } else {
      router.push('/authPage');
    }
  };
  return (
    <div className="transform-wrapper">
    <div className="transform-container">
      <h1>
        <FormattedMessage 
          id="transform.title" 
          defaultMessage="Ready to Transform Your AI Workflow?" 
        />
      </h1>
      <p>
        <FormattedMessage 
          id="transform.description" 
          defaultMessage="Join thousands of professionals using our platform to streamline their AI operations" 
        />
      </p>
      <button className="start-button" onClick={handleClick}>
        <i className="feather-zap"></i>
        <FormattedMessage 
          id="transform.button" 
          defaultMessage="Get Started Free" 
        />
      </button>
    </div>
      <style jsx>{`
          .transform-wrapper {
            padding: 0 20px;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
            flex:1;
          }

          .transform-container {
            background: linear-gradient(45deg, #4066E0 0%, #834DE8 100%);
            
            padding: 60px 280px;
            border-radius: 16px;
            text-align: center;
            color: white;
            margin: 40px auto; // Üst ve alttan margin ekledim
          }

          h1 {
            font-size: clamp(2rem, 5vw, 2.5rem);
            font-weight: bold;
            margin-bottom: 1rem;
            color: white;
          }

          p {
            font-size: clamp(1.1rem, 2vw, 1.4rem);
            margin-bottom: 2rem;
            opacity: 0.9;
            color: white;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
          }

          .start-button {
            background: white;
            color: #4066E0;
            border: none;
            border-radius: 25px;
            padding: 15px 30px;
            font-size: clamp(1rem, 2vw, 1.3rem);
            font-weight: 600;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            transition: all 0.3s ease;
          }

          .start-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          }

          i {
            font-size: 1.2rem;
          }

          @media (max-width: 768px) {
            .transform-wrapper {
              padding: 0 15px;
            }

            .transform-container {
              padding: 40px 20px;
              margin: 20px auto;
            }
          }
        `}</style>
    </div>
    
  );
};

export default ReadyToTransform;
