import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useList from '@/components/isAuth/isAuth';
import { useRouter } from 'next/router';
import Link from "next/link";


const DesignHomeHead = () => {
  const [animate, setAnimate] = useState(false);
  const router = useRouter();
  const list = useList();
  const userID = list[0];
  const m = list[3];
  useEffect(() => {
    setAnimate(true);
  }, []);
useEffect(()=>{
  console.log(userID)
  console.log(m)
},[userID])
  const handleTryClick = () => {
    if (userID) {
      router.push('/chat');
    } else {
      router.push('/AuthPage');
    }
  };

  return (
    <>
      <div className='concontainer' style={{paddingTop:"0px"}}>
        <div className="header-container">
          <h1 className={`main-title ${animate ? 'animate' : ''}`} style={{marginBottom:"10px"}}>
            <span className="black-text">  <FormattedMessage id="Home.design.title" /></span>
            <span className="blue-text">  <FormattedMessage id="Home.design.title2" /></span>
          </h1>
          <p className={`subtitle ${animate ? 'animate' : ''}`}style={{marginBottom:"10px"}} > 
          <FormattedMessage id="Home.design.subtitle" />
          </p>
          <div className={`button-group ${animate ? 'animate' : ''}`}>
            <button className="start-trial-button" onClick={handleTryClick}>
            
           {userID === null ? (  <><FormattedMessage id="start.free.trial"/> →</>):(<><FormattedMessage id="start.free.go"/> →</>)}
            </button>
            <button className="view-pricing-button" >
            <Link href="/pricing" style={{color:"#333"}}>
            <FormattedMessage id="Home.design.pricing" />
            </Link></button>
         
          </div>
        </div>
      </div>
      <style jsx>{`
        .concontainer {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding-top:73px;
         
        }

        .header-container {
          text-align: center;
        }

        .main-title {
          font-size: 48px;
          line-height: 1.2;
        
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.5s, transform 0.5s;
        }

        .black-text {
          color:  var(--welcome-slogan);
        }

        .blue-text {
          color: #4169E1;
        }

        .subtitle {
          font-size: 18px;
          color: #666;
          max-width: 800px;
          margin: 0 auto 32px;
          line-height: 1.6;
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.5s, transform 0.5s;
          transition-delay: 0.2s;
        }

        .button-group {
          display: flex;
          gap: 8px;
          justify-content: center;
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.5s, transform 0.5s;
          transition-delay: 0.4s;
        }

        .start-trial-button {
          padding: 12px 24px;
          background-color: #4169E1;
          color: white;
          border: none;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .view-pricing-button {
          padding: 12px 24px;
          background-color: #f5f5f5;
          color: #333;
          border: none;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .start-trial-button:hover {
          background-color: #3557b7;
        }

        .view-pricing-button:hover {
          background-color: #e5e5e5;
        }

        .main-title.animate,
        .subtitle.animate,
        .button-group.animate {
          opacity: 1;
          transform: translateY(0);
        }

        @media (max-width: 768px) {
          .main-title {
            font-size: 36px;
          }

          .subtitle {
            font-size: 16px;
          }

          .button-group {
            flex-direction: column;
            align-items:center;
          }

          .start-trial-button,
          .view-pricing-button {
            width: 50%;
          }
        }
      `}</style>
    </>
  );
};

export default DesignHomeHead;
