import React from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/llm-home/nova_explosion.png";

import useList from '@/components/isAuth/isAuth';

const MediumDesign = () => {
    const list = useList();
    const intl = useIntl();
    const userID = list[0];

    return (
        <>
           <div className="container">
            <h1>
                <FormattedMessage id="mediumdesign.title" />
            </h1>
            
            <div className="features-container">
                <div className="column traditional">
                    <h2>
                        <FormattedMessage id="mediumdesign.traditional.title" />
                    </h2>
                    <ul>
                        <li>
                            <i className="feather-x"></i>
                            <FormattedMessage id="mediumdesign.traditional.item1" />
                        </li>
                        <li>
                            <i className="feather-x"></i>
                            <FormattedMessage id="mediumdesign.traditional.item2" />
                        </li>
                        <li>
                            <i className="feather-x"></i>
                            <FormattedMessage id="mediumdesign.traditional.item3" />
                        </li>
                        <li>
                            <i className="feather-x"></i>
                            <FormattedMessage id="mediumdesign.traditional.item4" />
                        </li>
                    </ul>
                </div>

                <div className="column llmwizard">
                    <h2>
                        <FormattedMessage id="mediumdesign.llmwizard.title" />
                    </h2>
                    <ul>
                        <li>
                            <i className="feather-check"></i>
                            <FormattedMessage id="mediumdesign.llmwizard.item1" />
                        </li>
                        <li>
                            <i className="feather-check"></i>
                            <FormattedMessage id="mediumdesign.llmwizard.item2" />
                        </li>
                        <li>
                            <i className="feather-check"></i>
                            <FormattedMessage id="mediumdesign.llmwizard.item3" />
                        </li>
                        <li>
                            <i className="feather-check"></i>
                            <FormattedMessage id="mediumdesign.llmwizard.item4" />
                        </li>
                    </ul>
                </div>

                <div className="column benefits">
                    <h2>
                        <FormattedMessage id="mediumdesign.benefits.title" />
                    </h2>
                    <ul>
                        <li>
                            <i className="feather-plus"></i>
                            <FormattedMessage id="mediumdesign.benefits.item1" />
                        </li>
                        <li>
                            <i className="feather-plus"></i>
                            <FormattedMessage id="mediumdesign.benefits.item2" />
                        </li>
                        <li>
                            <i className="feather-plus"></i>
                            <FormattedMessage id="mediumdesign.benefits.item3" />
                        </li>
                        <li>
                            <i className="feather-plus"></i>
                            <FormattedMessage id="mediumdesign.benefits.item4" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>


            <style jsx>{`
                .container {
                    padding: 40px;
                    max-width: 1200px;
                    margin: 0 auto;
                    margin-top: 40px;
                    
                }

                h1 {
                    text-align: center;
                    margin-bottom: 40px;
                    font-size: 32px;
                }

                .features-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;
                }

                .column {
                    flex: 1;
                    padding: 24px;
                    border-radius: 12px;
                }

                .llmwizard {
                    background-color: #f8f9ff;
                }

                h2 {
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                }

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    font-size: 16px;
                }

                i {
                    margin-right: 12px;
                }

                .traditional i {
                    color: #ff4444;
                }

                .llmwizard i {
                    color: #4CAF50;
                }

                .benefits i {
                    color: #2196F3;
                }
            `}</style>
        </>
    );
};

export default MediumDesign;

