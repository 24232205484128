import { v4 as uuidv4 }   from 'uuid'
import Showdown from 'showdown';


// Eylem Türü Tanımlama


// Eylem Oluşturucu Tanımlama
// Eylem oluşturucular
export const startStreamingMessage = (author) => ({
  type: 'START_STREAMING_MESSAGE',
  payload: { content: '', author }
});

export const updateStreamingMessage = (char) => ({
  type: 'UPDATE_STREAMING_MESSAGE',
  payload: char
});

export const setReferences = (array) => ({
  type: 'SET_REFERENCES',
  payload: array
});


export const endStreamingMessage = () => ({
  type: 'END_STREAMING_MESSAGE'
});



export const addMessage = (newMessage) => ({
  type:'ADD_MESSAGE',
  payload: {

    content:newMessage.content,
    author:newMessage.author,
    model:newMessage.model,
    files:newMessage.files,
    imageUrl:newMessage.imageUrl
  }
});
export const addInitialMessage = (newMessage) => ({
  type:'ADD_INITIAL',
  payload: {
    content:newMessage,
    author:"ai"
  }
});


export const handleChatHistory = (newMessage) => {
  try {
    const history = [];
    
    if (!newMessage || typeof newMessage !== 'object') {
      console.error('Invalid message format:', newMessage);
      return {
        type: 'HANDLE_HISTORY',
        payload: []
      };
    }

    const userMessages = Array.isArray(newMessage.user_messages) 
      ? newMessage.user_messages 
      : [];
    
    const assistantMessages = Array.isArray(newMessage.assistant_messages) 
      ? newMessage.assistant_messages 
      : [];

    const maxLength = Math.max(userMessages.length, assistantMessages.length);

    for (let i = 0; i < maxLength; i++) {
      // Kullanıcı mesajlarını ekle
      if (i < userMessages.length && userMessages[i]) {
        const userMessage = {
          content: typeof userMessages[i] === 'object' 
            ? userMessages[i].content 
            : userMessages[i],
          author: "user"
        };

        // Add image if it exists in user message
        if (typeof userMessages[i] === 'object' && userMessages[i].image) {
          userMessage.image = userMessages[i].image;
        }

        history.push(userMessage);
      }
   
      // Asistan mesajlarını ekle
      if (i < assistantMessages.length && assistantMessages[i]) {
        const isImageModel = newMessage.llm_info && 
          newMessage.llm_info.model !== undefined && 
          (newMessage.llm_info.model_id === 1 || newMessage.llm_info.model_id === 2);

        if (isImageModel) {
          try {
            const parsedData = typeof assistantMessages[i] === 'string' 
              ? JSON.parse(assistantMessages[i])
              : assistantMessages[i];

            history.push({
              content: parsedData.text || '',
              imageUrl: parsedData.url || '',
              model: parsedData.model,
              author: "ai"
            });
          } catch (error) {
            console.error('Error parsing assistant message:', error);
            history.push({
              content: assistantMessages[i],
              author: "ai"
            });
          }
        } else {
          if (assistantMessages[i] && assistantMessages[i].length > 0) {
            history.push({
              content: assistantMessages[i],
              author: "ai"
            });
          }
        }
      }
    }
 
    return {
      type: 'HANDLE_HISTORY',
      payload: history
    };
  } catch (error) {
    console.error('Error in handleChatHistory:', error);
    return {
      type: 'HANDLE_HISTORY',
      payload: []
    };
  }
};


// Diğer reducer ve action'lar aynı kalabilir...




export const handleBotChatHistory = (newMessage) => {

  const history = []
  const userMessages = newMessage.user_messages || [];
  const assistantMessages = newMessage.assistant_messages || [];
  const maxLength = Math.max(userMessages.length, assistantMessages.length);


  for (let i = 0; i < maxLength; i++) {
    if (i < userMessages.length) {
      history.push({
        content: userMessages[i],
        author: "user"
      });
    }
    if (i < assistantMessages.length) {
      
      history.push({
        content: assistantMessages[i],
        author: "ai"
      });
    }
  }

  
  return {
  type:'HANDLE_HISTORY',
  payload: history
}};



// Reducer
export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES'
});




const initialState ={
  response:[], //{content:"Hello, how can I help you?",author:"ai",references:[]}
   streamingMessage: null, 
   references:[]
  }

export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
       
      return   {
        ...state,
        response: [...state.response, action.payload]
      };
    case 'ADD_INITIAL':
      return {
        ...state,
        response:[{content:action.payload.content,author:action.payload.author,references:[]}],
        streamingMessage:null

      }  
    case 'CLEAR_MESSAGES':
      return initialState
    
      case 'START_STREAMING_MESSAGE':
        return {
          ...state,
          streamingMessage: { content: '', author: action.payload.author }
        };
  
      case 'UPDATE_STREAMING_MESSAGE':
        if (!state.streamingMessage) return state; // Eğer streaming başlamamışsa, bir şey yapma
        return {
          ...state,
          streamingMessage: {
            ...state.streamingMessage,
            content: state.streamingMessage.content + action.payload // Harf ekleniyor
          }
        };
      case 'SET_REFERENCES':
        return {
          ...state,
          references:action.payload
        }
  
      case 'END_STREAMING_MESSAGE':
        if (!state.streamingMessage) return state; // Eğer streaming başlamamışsa, bir şey yapma
        return {
          ...state,
          response: [...state.response, {...state.streamingMessage,references:state.references}], // Mesajı nihai olarak ekle
          streamingMessage: null // Streaming bitti, durumu sıfırla
          

        };
      case 'HANDLE_HISTORY':
       return {
        ...state,
        response: Array.isArray(action.payload) ? action.payload : [],
        streamingMessage: null
      };     
        return {
          ...state,
          response: action.payload
        };
    default:
      return state;
  }
};

