import React, { useEffect, useRef,useState } from "react";
import { FormattedMessage ,useIntl  } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/menu-img/llms.png";
import Image from "next/image";
import { useRouter } from 'next/router';


import useList from '@/components/isAuth/isAuth';
import assistant from '@/public/images/menu-img/assistant.png'
import llmwizardAi from '@/public/images/menu-img/llmwizard-ai.jpg'


const Features = () => {
    const list = useList();
    const intl = useIntl();
    const router = useRouter();

    const handleTryClick = () => {
      if (userID) {
        router.push('/chat');
      } else {
        router.push('/AuthPage');
      }
    };
    const userID = list[0];
 
    return (
      <>
        <style jsx>{`
          .rainbow-accordion-area {
            padding: 80px 0;
            background: #fff;
          }
          .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 15px;
          }
          .features-header {
            text-align: center;
            margin-bottom: 60px;
          }
          .features-header h2 {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 20px;
          }
          .features-header p {
            color: #666;
            max-width: 700px;
            margin: 0 auto;
            margin-bottom: 30px;
          }
          .ask-button {
            display: inline-block;
            padding: 12px 30px;
            background-color: #4776E6;
            color: white;
            border-radius: 25px;
            border-color: #4776E6;
            font-size: 17px;
            border:none;
            font-weight: 400;
            text-decoration: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          .ask-button:hover {
            background-color: #3560c8;
          }
          .features-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            margin-top: 40px;
          }
          .feature-card {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: start;
        background: transparent; /* Remove background from card */
      }

      .feature-image-container {
        width: 100%;
        padding: 20px;
        display: flex;
        height: 500px;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        margin-bottom: 20px;
        background: #f8f9fa;
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease;
      }

      .feature-image-container:hover {
        transform: translateY(-5px);
      }

      .feature-image-container:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7));
        opacity: 0;
        transition: opacity 0.3s ease;
        z-index: 1;
        border-radius: 25px;
      }

      .feature-image-container:hover:before {
        opacity: 1;
      }

      .feature-image-container.dark {
        background: linear-gradient(45deg, #000000, #1a1a1a);
      }


      .feature-image {
        width: 80%;
        height: auto;
        position: relative;
        border-radius: 25px; /* Higher border radius */ 
      }

      .feature-content {
        text-align: center;
      }

      .feature-content h3 {
        font-size: 24px;
        margin-bottom: 15px;
        text-align: left;
        color: #000; /* Reset color for both cards */
      }

      .feature-content p {
        color: #666;
        line-height: 1.6;
        text-align: start;
      }

      /* Remove dark theme styles for card */
      .feature-card.dark .feature-content h3,
      .feature-card.dark .feature-content p {
        color: #333; /* Reset text color */
      }

      @media (max-width: 768px) {
        .features-grid {
          grid-template-columns: 1fr;
        }
      }
    `}</style>
    <div className="rainbow-accordion-area rainbow-section-gap">
      <div className="container">
        <div className="features-header">
          <h2>
            <FormattedMessage
              id="why.choose.llmwizard"
              defaultMessage="Why Choose LLMWizard?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="save.money.description"
              defaultMessage="Save money with one subscription instead of many. Access all major AI models through a single, consistent interface."
            />
          </p>
          <button className="ask-button"  onClick={handleTryClick}>
            <FormattedMessage
              id="ask.llmwizard"
              defaultMessage="Ask LLMWizard Anything"
            />
          </button>
        </div>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-image-container dark">
              <div style={{borderRadius: 20}} className="feature-image">
                <Image 
                  style={{borderRadius: 20}}
                  src={llmwizardAi}
                  alt="LLMWizard AI"
                  layout="responsive"
                  quality={100}
                />
              </div>
            </div>
            <div className="feature-content">
              <h3>
                <FormattedMessage
                  id="access.models.title"
                  defaultMessage="Access Multiple AI Models Simultaneously"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="access.models.description"
                  defaultMessage="LLMWizard can be your unified AI interface, combining the strengths of multiple models to provide comprehensive insights."
                />
              </p>
            </div>
          </div>
          <div className="feature-card">
            <div className="feature-image-container">
              <div style={{borderRadius: 20}} className="feature-image">
                <Image 
                  style={{borderRadius: 20}}
                  src={assistant}
                  alt="AI Assistant"
                  layout="responsive"
                  quality={100}
                />
              </div>
            </div>
            <div className="feature-content">
              <h3>
                <FormattedMessage
                  id="compare.analyze.title"
                  defaultMessage="Compare and Analyze AI Responses"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="compare.analyze.description"
                  defaultMessage="Upload your content and let multiple AI models analyze it simultaneously. Compare different approaches and insights."
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      </>
    );
    
    
    
};

export default Features;
