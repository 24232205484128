import React,{ useEffect, useRef, useState } from "react";
import { FormattedMessage ,useIntl } from 'react-intl';
import AccordionData from "../../data/home.json";
import Accord from "./Accord";
const AccordionItem = () => {
  const expanded = [
    false,false,false,false,false,false
  ]
  const intl = useIntl();
  const containerRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const scrollSpeed = 1; // Daha düşük bir hız değeri

  useEffect(() => {
    const container = containerRef.current;
    let scrollInterval;
    let resetTimeout;

    const autoScrollDiv = () => {
      if (autoScroll) {
      
        container.scrollTop += scrollSpeed;
        
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 1) {
          clearInterval(scrollInterval);
          container.scrollTop = 0;
          resetTimeout = setTimeout(() => {
            startAutoScroll();
          }, 1000); // 1 saniye gecikme ile başa döner ve tekrar başlar
        }
      }
    };

    const startAutoScroll = () => {
      if (autoScroll) {
        scrollInterval = setInterval(autoScrollDiv, 16); // 16ms interval for smoother scrolling (60 FPS)
      }
    };
    

    const stopAutoScroll = () => {
      clearInterval(scrollInterval);
      clearTimeout(resetTimeout);
    };

    const handleMouseEnter = () => {
      setAutoScroll(false);
      stopAutoScroll();
    };

    const handleMouseLeave = () => {
      setAutoScroll(true);
      startAutoScroll();
    };

    container.addEventListener('mouseenter', handleMouseEnter);
    container.addEventListener('mouseleave', handleMouseLeave);

    startAutoScroll();

    return () => {
      stopAutoScroll();
      container.removeEventListener('mouseenter', handleMouseEnter);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [autoScroll]);
  return (
    <>
      <div className="rainbow-accordion-style  accordion faq-faq">
        <div className="accordion faq-scroll" id="accordionExamplea" ref={containerRef} style={{height:"600px",overflowY:"scroll",scrollBehavior:"smooth",transition:"transform 0.5s ease"}}>
          {AccordionData &&
            AccordionData.accordion.map((data, index) => (
              <div className="accordion-item card bg-flashlight" key={index}>
                <h3
                  className="accordion-header card-header"
                  id={`heading${index + 1}`}
                >
                  <button
                    className={`accordion-button ${
                      !expanded[index] ? "collapsed" : ""
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index + 1}`}
                    aria-expanded={expanded[index] ? "true" : "false"}
                    aria-controls={`collapse${index + 1}`}
                  >
                    <FormattedMessage id={`Q${index}.title`} />
                  </button>
                </h3>
                <div
                  id={`collapse${index + 1}`}
                  className={`accordion-collapse collapse ${
                    expanded[index] ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index + 1}`}
                  data-bs-parent="#accordionExamplea"
                >


                  <div className="accordion-body card-body">
                  <Accord index={index}/>
                
               
                  
                  </div>
                </div>
              </div>
            ))}
            <div style={{height:"50px"}}> </div>
        </div>
      </div>
    </>
  );
};

export default AccordionItem;
