import React from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import llms from "../../public/images/llm-home/nova_explosion.png";
import Image from "next/image";
import useList from '@/components/isAuth/isAuth';
import custom_solutions from "../../public/images/llm-home/Custom solutions.png";
import brave from "../../public/images/llm-home/brave.jpg";
import { useRouter } from 'next/router'; // Add this import

const DesignWorkflow = () => {
    const list = useList();
    const intl = useIntl();
    const userID = list[0];
    const router = useRouter(); // Initialize router
    const handleNavigation = () => {
        if (userID) {
            router.push('/chat');
        } else {
            router.push('/authPage');
        }
    };

    return (
        <>
         <div className="container1">
         <div className="header">
            <h1>
                <FormattedMessage id="workflow.title" />
            </h1>
            <h2>
                <FormattedMessage id="workflow.subtitle" />
            </h2>
            <p>
                <FormattedMessage id="workflow.description" />
            </p>
            <button className="cta-button" onClick={handleNavigation}>
                <FormattedMessage id="workflow.getStarted" />
                <i className="feather-arrow-right"></i>
            </button>
        </div>

        <div className="features">
            <div className="feature-card">
                <i className="icons feather-align-left"></i>
                <h3>
                    <FormattedMessage id="workflow.feature1.title" />
                </h3>
                <p>
                    <FormattedMessage id="workflow.feature1.description" />
                </p>
                <button className="learn-more" onClick={handleNavigation}>
                    <FormattedMessage id="workflow.learnMore" />
                    <i className="feather-arrow-right"></i>
                </button>
                <div className="feature-image-1">
                    <Image style={{borderRadius:20}} src={brave} alt="Brave" />
                </div>
            </div>

            <div className="feature-card">
                <i className="icons feather-layers"></i>
                <h3>
                    <FormattedMessage id="workflow.feature2.title" />
                </h3>
                <p>
                    <FormattedMessage id="workflow.feature2.description" />
                </p>
                <button className="learn-more" onClick={handleNavigation}>
                    <FormattedMessage id="workflow.learnMore" />
                    <i className="feather-arrow-right"></i>
                </button>
                <div className="feature-image-2">
                    <Image style={{borderRadius:20}} src={custom_solutions} alt="Custom solutions" />
                </div>
            </div>
        </div>
        </div>

            <style jsx>{`
                .container1 {
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 20px;
                    flex:1;
                }

                .header {
                    background-color: #FFF0F5;
                    padding: 60px 20px;
                    text-align: center;
                    border-radius: 20px;
                    margin-bottom: 40px;
                }

                h1 {
                    font-size: 3rem;
                    margin-bottom: 16px;
                    font-weight: 700;   
                }

                    h3{
                        margin-top:20px;
                    }
                h2 {
                    font-size: 2rem;
                    margin-bottom: 20px;
                    color: #333;
                    font-weight: 600;
                }
                .icons{
                    font-size: 25px;
                    color: #1a1a1a;
                    margin-bottom: 20px;
                }
                p {
                    max-width: 800px;
                    margin: 0 auto 24px;
                    line-height: 1.6;
                    color: #444;
                }

                .cta-button {
                    background-color: #1a1a1a;
                    color: white;
                    padding: 12px 24px;
                    border-radius: 25px;
                    border: none;
                    cursor: pointer;
                    font-size: 1.3rem;
                }

                .cta-button i,
                .learn-more i {
                    margin-left: 8px;
                    vertical-align: middle;
                }

                .feather-arrow-right {
                    stroke: currentColor;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    fill: none;
                }

                .cta-button:hover i,
                .learn-more:hover i {
                    transform: translateX(4px);
                    transition: transform 0.2s ease;
                }
                .features {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 40px;
                }

                .feature-card {
                    background-color: white;
                    padding: 40px;
                    border-radius: 20px;
                    height: 700px;
                    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
                }

                .feature-card h3 {
                    font-size: 1.8rem;
                    margin-bottom: 20px;
                }

                .learn-more {
                    background-color: #1a1a1a;
                    color: white;
                    padding: 10px 20px;
                    border-radius: 20px;
                    border: none;
                    cursor: pointer;
                    margin-top: 20px;
                }

                .feature-image-1 {
                    height: 100px;
                    margin-top: 30px;
                    border-radius: 10px;
                }

                .feature-image-2 {
                    height: 200px;
                    margin-top: 30px;
                    border-radius: 10px;
                }

                @media (max-width: 768px) {
                    .features {
                        grid-template-columns: 1fr;
                    }
                }
            `}</style>
        </>
    );
};

export default DesignWorkflow;
